import React from 'react';
import { useStateIfMounted } from 'use-state-if-mounted';
import { Typography, styled } from '@mui/material';

import { APED_LEVELS } from 'constants/bonus';
import { formatNumber } from 'utils/numbers';
import { getXOauthUrl } from 'utils/x';
import { useWallet } from 'context/WalletContext';

import { Text } from 'components/Text';
import { ApedIcon, PersonIcon, GreenCheckIcon } from 'components/ImageComponent';
import {
  PromotionCard,
  BonusAmountText,
  ReserveBonusButton,
  FlexBox,
  Container,
} from 'components/PromotionCard';
import { ActivatedButton, LevelProgressDot } from 'components/StyledComponents';
import ApedInTooltipModal from 'components/PromotionCard/modals/ApedInTooltipModal';
import { ConnectButton, StandardButton } from 'components/Button';

/********************  Styled Components  ********************/
const Flex = styled('div')(() => ({
  display: 'flex',
  gap: 8,
  alignItems: 'center',
  justifyContent: 'center',
}));

const FlexDots = styled('div')(() => ({
  display: 'flex',
  gap: 4,
  cursor: 'default',
  alignItems: 'center',
}));

/********************  Sub Component  ********************/
const SpotsLeft = ({ spotsRemaining }) => (
  <Flex>
    <PersonIcon />
    <Text fw={600} md={{ fs: 14, fw: 500, lh: 20 }}>
      {formatNumber(spotsRemaining)}
    </Text>
  </Flex>
);

/********************  Main Component  ********************/
const ApedCard = ({ completed, spotsLeft, percentage, refetchData, level }) => {
  const [tooltipOpen, setTooltipOpen] = useStateIfMounted(false);
  const { isAuthenticated, twitterUser, wallet } = useWallet();

  const xLink = getXOauthUrl(wallet);

  return (
    <>
      <PromotionCard
        Icon={ApedIcon}
        title="Aped In Bonus"
        tooltip={true}
        handleTooltip={() => setTooltipOpen(true)}
        completed={completed}
      >
        <Container>
          <FlexBox>
            <FlexDots>
              {Array(APED_LEVELS.maxLevel)
                .fill(null)
                .map((_, index) => (
                  <FlexDots key={index}>
                    {completed ? (
                      index + 1 === level ? (
                        <LevelProgressDot green>
                          <GreenCheckIcon width="20" height="20" />
                        </LevelProgressDot>
                      ) : (
                        <LevelProgressDot green={index < level} />
                      )
                    ) : (
                      <LevelProgressDot yellow={index <= level} />
                    )}
                  </FlexDots>
                ))}
            </FlexDots>
            <Typography fontSize={12} fontWeight={500}>
              {!completed && 'SPOTS LEFT:'}
            </Typography>
          </FlexBox>
          <FlexBox marginTop={1}>
            {completed ? (
              <Text>
                <Typography component="span" color="#F9D402" fontWeight={600}>
                  +1000 Gold
                </Typography>{' '}
                for Completing!
              </Text>
            ) : (
              <>
                <BonusAmountText value={percentage} />
                <SpotsLeft spotsRemaining={spotsLeft} />
              </>
            )}
          </FlexBox>
          {!isAuthenticated ? (
            <ConnectButton height={40} />
          ) : !twitterUser ? (
            <StandardButton href={xLink}>Sign in with 𝕏</StandardButton>
          ) : completed ? (
            <ActivatedButton>{percentage}% BONUS Activated</ActivatedButton>
          ) : (
            <ReserveBonusButton apedIn refetchData={refetchData} percentage={percentage} />
          )}
        </Container>
      </PromotionCard>

      <ApedInTooltipModal isOpen={tooltipOpen} onClose={() => setTooltipOpen(false)} />
    </>
  );
};

export default ApedCard;
