import React, { useContext } from 'react';
import { CircularProgress, styled } from '@mui/material';
import { useStateIfMounted } from 'use-state-if-mounted';
import { useNavigate } from 'react-router-dom';

import { EMAIL_ADDRESS_VALIDATOR } from 'utils/validators';
import { ROUTES } from 'pages/routes';
import { GMI_LINKS } from 'constants';
import { EARLY_DEGEN_LEVELS } from 'constants/bonus';
import { startEmailTimer } from 'utils/emailTimer';
import { DashboardContext } from 'context/DashboardContext';
import { useWallet } from 'context/WalletContext';
import emailService from 'services/email';
import useNotification from 'hooks/useNotification';

import Modal from 'components/Modal';
import { Text, GreenLinkText } from 'components/Text';
import TextInput from 'components/TextInput';
import { StandardButton } from 'components/Button';
import { BonusDescription } from 'components/PromotionCard';
import { EarlyDegenIcon } from 'components/ImageComponent';

/********************  Styled Components  ********************/
const Container = styled('div')(() => ({
  display: 'flex',
  width: '100%',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  padding: '32px 16px 24px 16px',
  gap: 16,
}));

const ButtonWrapper = styled('div')(() => ({
  padding: '16px',
  width: '100%',
}));

/********************  Main Component  ********************/
const EarlyDegenModal = ({ isOpen, onDismiss }) => {
  const [inputValue, setInputValue] = useStateIfMounted({ name: '', email: '' });
  const [nameError, setNameError] = useStateIfMounted({ value: false, message: '' });
  const [emailError, setEmailError] = useStateIfMounted({ value: false, message: '' });
  const [isLoading, setIsLoading] = useStateIfMounted(false);

  const navigate = useNavigate();
  const { wallet } = useWallet();
  const showNotification = useNotification();
  const { state } = useContext(DashboardContext);

  const level = state.data.bonuses.filter((item) => item.name === 'earlyDegen')[0].level;
  const amount = EARLY_DEGEN_LEVELS[level].percentage;

  const handleReserveBonus = async () => {
    const isValid = validateInputs(inputValue.name, inputValue.email);

    if (isValid) {
      setIsLoading(true);
      const body = { name: inputValue.name, address: wallet, email: inputValue.email };
      const res = await emailService.reserveEmail(body);
      handleEmailResponse(res);
      setIsLoading(false);
    }
  };

  const handleEmailResponse = (res) => {
    if (res.success) {
      navigate(ROUTES.ConfirmEmail, { state: { email: inputValue.email } });
      startEmailTimer();
    } else {
      showNotification({
        type: 'error',
        message: res.message,
      });
    }
  };

  const onChange = (e) => {
    setInputValue({ ...inputValue, [e.target.name]: e.target.value });

    if (e.target.name === 'name' && e.target.value) {
      setNameError({ value: false, message: '' });
    }
    if (e.target.name === 'email' && e.target.value) {
      setEmailError({ value: false, message: '' });
    }
  };

  const validateInputs = (name, email) => {
    let isValid = true;
    if (!name) {
      setNameError({ value: true, message: 'This field is required' });
      isValid = false;
    }
    if (!email) {
      setEmailError({ value: true, message: 'This field is required' });
      isValid = false;
    }
    if (!EMAIL_ADDRESS_VALIDATOR.test(email)) {
      setEmailError({ value: true, message: 'Enter a valid email' });
      isValid = false;
    }
    return isValid;
  };

  return (
    <Modal title="Activate your bonus" isOpen={isOpen} onDismiss={onDismiss} width={556}>
      <Container>
        <Text tertiary="true" fs={14} fw={500} lh={20}>
          All fields are required
        </Text>
        <TextInput
          width="100%"
          height={48}
          placeholder="Name"
          name="name"
          value={inputValue.name}
          onChange={(e) => onChange(e)}
          error={nameError.value}
          helperText={nameError.value && nameError.message}
        />
        <TextInput
          width="100%"
          height={48}
          placeholder="Email"
          name="email"
          value={inputValue.email}
          onChange={onChange}
          error={emailError.value}
          helperText={emailError.value && emailError.message}
        />
        <Text
          tertiary="true"
          align="left"
          fs={14}
          fw={500}
          lh={20}
          sx={{ pr: 1, letterSpacing: '0.25px' }}
        >
          We&apos;re committed to your privacy. GonnaMakeIt uses the information you provide to us
          contact you about our relevant content, products, and services. You may unsubscribe from
          these communications at any time. For more information, check out our{' '}
          <GreenLinkText fs={14} fw={500} lh={20} href={GMI_LINKS.PrivacyPolicy}>
            Privacy Policy.
          </GreenLinkText>
        </Text>
      </Container>

      <BonusDescription bonuses={[{ icon: EarlyDegenIcon, name: 'Early Degen', amount }]} />

      <ButtonWrapper>
        <StandardButton width="100%" height={48} onClick={handleReserveBonus}>
          {isLoading ? <CircularProgress color="inherit" sx={{ p: '4px' }} /> : 'Activate now'}
        </StandardButton>
      </ButtonWrapper>
    </Modal>
  );
};

export default EarlyDegenModal;
