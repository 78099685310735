import {
  Typography,
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  styled,
} from '@mui/material';

import { openLink } from 'utils/openLink';
import { LEVELS } from 'constants/bonusS2';

import Modal from 'components/Modal';
import { GreenSpanText } from 'components/Text';
import { FlexCenter } from 'components/StyledComponents';

const Container = styled(Box)(({ theme }) => ({
  padding: theme.spacing(3),
  textAlign: 'left',
  width: '100%',
}));

const StickyTable = styled(TableContainer)(({ theme }) => ({
  maxHeight: '400px',
  overflowX: 'hidden',
  overflowY: 'auto',
  '& th': {
    position: 'sticky',
    top: 0,
    background: theme.palette.background.tertiary,
    zIndex: 1,
  },
}));

const StyledTableBody = styled(TableBody)(({ theme }) => ({
  background: theme.palette.background.default,
}));

const modalContentStyles = {
  maxHeight: '90vh',
  overflow: 'hidden',
};

const LevelUpModal = ({ onClose }) => {
  return (
    <Modal title="Level Up Bonus" isOpen={true} onDismiss={onClose} width={524}>
      <Container>
        <Typography fontSize={14} fontWeight={400} marginTop={0} marginBottom={4}>
          More information about Level Up Bonus can be found{' '}
          <GreenSpanText
            sx={{ textDecoration: 'underline', cursor: 'pointer' }}
            onClick={() =>
              openLink(
                'https://docs.gonnamakeit.com/docs/airdrop/gmi-airdrop-current#491-xp-level-up-bonus',
              )
            }
          >
            here
          </GreenSpanText>
          .
        </Typography>
        <FlexCenter>
          <Box sx={modalContentStyles}>
            <StickyTable component={Paper}>
              <Table stickyHeader>
                <TableHead>
                  <TableRow>
                    <TableCell>Level</TableCell>
                    <TableCell>Points</TableCell>
                    <TableCell>Boost</TableCell>
                  </TableRow>
                </TableHead>
                <StyledTableBody>
                  {LEVELS.map((row) => (
                    <TableRow key={row.level}>
                      <TableCell>{row.level}</TableCell>
                      <TableCell align="right">{row.points}</TableCell>
                      <TableCell align="right">{row.boost}%</TableCell>
                    </TableRow>
                  ))}
                </StyledTableBody>
              </Table>
            </StickyTable>
          </Box>
        </FlexCenter>
      </Container>
    </Modal>
  );
};

export default LevelUpModal;
