import React, { Suspense, useEffect } from 'react';
import { useStateIfMounted } from 'use-state-if-mounted';
import { BrowserRouter, Routes, Route, useLocation } from 'react-router-dom';
import { ThemeProvider, styled } from '@mui/material';
import CssBaseline from '@mui/material/CssBaseline';
import { WagmiProvider } from 'wagmi';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';

import { AppContextProvider } from 'context/AppContext';
import { PromotionProvider } from 'context/PromotionContext';
import { LeaderboardS1Provider } from 'context/LeaderboardS1Context';
import { HodlerAirdropProvider } from 'context/HodlerAirdropContext';
import { darkTheme } from 'styles/theme';
import { PAGES, ROUTES } from 'pages/routes';
import { WalletProvider } from 'context/WalletContext';
import { config } from 'constants/walletConfig';

import NavBar from 'components/NavBar';
import LoadingBar from 'components/LoadingBar';
import ToastNotification from 'components/ToastNotification';
import { Onboarding } from './XDashboard/components';
import { NftVolumeProvider } from 'context/NftVolumeContext';
import { RewardsVaultProvider } from 'context/RewardsVaultContext';
import { ReferralProgramContestProvider } from 'context/ReferralProgramContestContext';

/********************  Styled Components  ********************/
const Body = styled('main')(({ theme, fullscreen }) => ({
  width: '100%',
  height: fullscreen === 'true' ? '100vh' : 'calc(100vh - 60px)', // 60px height of nav
  marginTop: fullscreen === 'true' ? 0 : 60,
  backgroundColor: theme.palette.background.default,

  '@media (max-width: 1439px)': {
    height: fullscreen === 'true' ? '100vh' : 'calc(100vh - 48px)', // 48px height of scaled nav
    marginTop: fullscreen === 'true' ? 0 : 48,
  },
  '@media (max-width: 1279px)': {
    height: fullscreen === 'true' ? '100vh' : 'calc(100vh - 60px)', // 60px height of nav
    marginTop: fullscreen === 'true' ? 0 : 60,
  },
}));

/********************  Main Component  ********************/
const version = '8.2.2';
console.log(
  `%cGMI Airdrop v${version}`,
  'font-style: italic; font-size: 1.5em; color: blue; border: 1px solid blue; border-radius: 2px; padding: 2px;',
);

const queryClient = new QueryClient();

export default function App() {
  return (
    <ThemeProvider theme={darkTheme}>
      <WagmiProvider config={config}>
        <QueryClientProvider client={queryClient}>
          <WalletProvider>
            <PromotionProvider>
              <LeaderboardS1Provider>
                <HodlerAirdropProvider>
                  <NftVolumeProvider>
                    <RewardsVaultProvider>
                      <ReferralProgramContestProvider>
                        <BrowserRouter>
                          <AppContextProvider>
                            <Root />
                          </AppContextProvider>
                          <CssBaseline />
                        </BrowserRouter>
                      </ReferralProgramContestProvider>
                    </RewardsVaultProvider>
                  </NftVolumeProvider>
                </HodlerAirdropProvider>
              </LeaderboardS1Provider>
            </PromotionProvider>
          </WalletProvider>
        </QueryClientProvider>
      </WagmiProvider>
    </ThemeProvider>
  );
}

const FULLSCREEN_PAGES = ['/'];

const Root = () => {
  const [showNavbar, setShowNavbar] = useStateIfMounted(true);
  const { pathname } = useLocation();

  useEffect(() => {
    if (FULLSCREEN_PAGES.includes(pathname)) {
      setShowNavbar(false);
    } else {
      setShowNavbar(true);
    }
  }, [pathname]);

  return (
    <>
      {showNavbar && <NavBar cursor={pathname === ROUTES.Dashboard} />}
      <Body fullscreen={Boolean(!showNavbar).toString()}>
        <Suspense fallback={<LoadingBar />}>
          <Routes>
            {PAGES.map((item, index) => (
              <Route key={index} {...item} />
            ))}
          </Routes>
        </Suspense>
      </Body>
      <ToastNotification />
      <Onboarding />
    </>
  );
};
