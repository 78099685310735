import { AIRDROP_FINISH_DATE, DAY_IN_MILLISECONDS } from 'constants';

export const convertMilisecondsToMinutes = (miliseconds) => {
  return Math.floor(miliseconds / 60000);
};

export const formatRemainingTime = (remainingTime) => {
  const remainingTimeInSeconds = Math.floor(remainingTime / 1000);
  const days = Math.floor(remainingTimeInSeconds / (24 * 3600));
  const hours = Math.floor((remainingTimeInSeconds % (24 * 3600)) / 3600);
  const minutes = Math.floor((remainingTimeInSeconds % 3600) / 60);

  const formattedDays = days.toString().padStart(2, '0');
  const formattedHours = hours.toString().padStart(2, '0');
  const formattedMinutes = minutes.toString().padStart(2, '0');

  return `${formattedDays}D ${formattedHours}H ${formattedMinutes}M`;
};

export const getDaysLeft = (floor = false) => {
  const timeLeft = (AIRDROP_FINISH_DATE.getTime() - new Date().getTime()) / DAY_IN_MILLISECONDS;
  if (timeLeft < 0) {
    return -1;
  }

  if (floor) {
    return Math.floor(timeLeft);
  }

  return Number(timeLeft.toFixed(0));
};

export const seasonDaysLeftMessage = () => {
  const daysLeft = getDaysLeft(true);
  let message;

  if (daysLeft === 0) {
    message = 'Ending soon!';
  } else if (daysLeft === 1) {
    message = 'Only 1 day left';
  } else {
    message = `Only ${daysLeft} days left`;
  }

  return message;
};
