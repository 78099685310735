import React, { useContext } from 'react';
import { useStateIfMounted } from 'use-state-if-mounted';
import { Link } from 'react-router-dom';
import { styled, useMediaQuery, Box, Stack, IconButton } from '@mui/material';
import { Close, Menu } from '@mui/icons-material';

import { ROUTES } from 'pages/routes';
import { useWallet } from 'context/WalletContext';
import { formatBalance } from 'utils/numbers';
import { openLink } from 'utils/openLink';
import { AppContext } from 'context/AppContext';

import { GMI, GMIIcon } from 'components/ImageComponent';
import { NavBarConnectButton } from 'components/Button';
import NavLinks from './components/NavLinks';
import AddressButton from './components/AddressButton';
import GmiAmount from './components/GmiAmount';
import SideNavigation from './components/SideNavigation';
import Cursor from 'assets/season2/cursor.svg';

/********************  Styled Components  ********************/
const Nav = styled('nav')(({ theme }) => ({
  width: '100vw',
  position: 'fixed',
  height: 60,
  display: 'flex',
  alignItems: 'center',
  backgroundColor: theme.palette.background.default,
  justifyContent: 'space-between',
  padding: '0 24px',
  top: 0,
  zIndex: 1300,
  boxShadow: theme.palette.boxShadow,

  '@media (max-width: 1439px)': {
    transform: 'scale(0.8)',
    transformOrigin: '0 0',
    width: 'calc(100% / 0.8)',
  },
  '@media (max-width: 1279px)': {
    transform: 'none',
    width: '100%',
  },
  '@media (max-width: 480px)': {
    padding: '0 16px',
  },
}));

const Container = styled(Box)(() => ({
  display: 'flex',
  alignItems: 'center',
}));

const SideNavigationButton = styled(IconButton)(() => ({
  display: 'none',

  '@media (max-width: 1150px)': {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginLeft: '24px',
  },
}));

/********************  Sub Component  ********************/
const LinkLogo = ({ logo }) => {
  return <Link onClick={() => openLink(ROUTES.Dashboard)}>{logo}</Link>;
};

/********************  Main Component  ********************/
const NavBar = ({ cursor = false }) => {
  const below480 = useMediaQuery('(max-width:480px)');
  const above1150 = useMediaQuery('(min-width:1150px)');

  const [sidebarOpen, setSidebarOpen] = useStateIfMounted(false);

  const { state: appState } = useContext(AppContext);
  const { isAuthenticated, wallet, twitterUser } = useWallet();

  return (
    <>
      <SideNavigation open={sidebarOpen} closeSideBar={() => setSidebarOpen(false)} />

      <Nav
        sx={{
          cursor: cursor ? `url(${Cursor}), auto` : 'auto',
          '& a': {
            cursor: cursor ? `url(${Cursor}), auto` : 'pointer',
          },
        }}
      >
        <Container>
          <Box sx={{ mr: above1150 ? '20px' : 0 }}>
            <LinkLogo logo={below480 ? <GMIIcon /> : <GMI />} />
          </Box>
          {above1150 ? <NavLinks /> : null}
        </Container>

        <Container>
          {isAuthenticated ? (
            <Stack direction="row">
              <GmiAmount amount={formatBalance(parseInt(appState.totalRewards))} />
              <AddressButton address={wallet} twitterName={twitterUser} />
            </Stack>
          ) : (
            <NavBarConnectButton />
          )}

          <SideNavigationButton onClick={() => setSidebarOpen(!sidebarOpen)}>
            {sidebarOpen ? <Close /> : <Menu />}
          </SideNavigationButton>
        </Container>
      </Nav>
    </>
  );
};

export default NavBar;
