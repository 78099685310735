import {
  Instagram as InstagramIcon,
  Twitter as TwitterIcon,
  Reddit as RedditIcon,
  YouTube as YouTubeIcon,
  Facebook as FacebookIcon,
  WhatsApp as WhatsAppIcon,
  LinkedIn as LinkedInIcon,
  InfoOutlined as InfoOutlinedIcon,
} from '@mui/icons-material';
import { styled } from '@mui/material/styles';

import Aped from 'assets/emojis/aped.png';
import EarlyDegen from 'assets/emojis/early-degen.png';
import GreenHeart from 'assets/emojis/green-heart.png';
import SpreadWord from 'assets/emojis/spread-word.png';
import LooksRare from 'assets/emojis/looks-rare.png';
import MoneyFace from 'assets/emojis/money-face.png';
import Ship from 'assets/emojis/ship.png';
import Spark from 'assets/emojis/spark.png';
import Sushi from 'assets/emojis/sushi.png';
import Bird from 'assets/emojis/twitter.png';
import Unicorn from 'assets/emojis/unicorn.png';
import X2Y2 from 'assets/emojis/x2y2.png';
import SmilingHeart from 'assets/emojis/smiling-heart.png';
import MoneyBag from 'assets/emojis/money-bag.png';
import Trophy from 'assets/emojis/trophy.png';
import HeartRibbon from 'assets/emojis/heart-with-ribbon.png';
import CongratsFace from 'assets/emojis/congrats-face.png';
import BronzeMedal from 'assets/emojis/bronze-medal.png';
import SilverMedal from 'assets/emojis/silver-medal.png';
import GoldMedal from 'assets/emojis/gold-medal.png';
import ETHStacker from 'assets/emojis/eth-stacker.png';
import ThinkFace from 'assets/emojis/thinking-face.png';
import SportsMedal from 'assets/emojis/sports-medal.png';
import Sparkles from 'assets/emojis/sparkles.png';
import MoneyWings from 'assets/emojis/money-wings.png';
import PouchPNG from 'assets/season2/pouch.png';
import { ReactComponent as DiamondSVG } from 'assets/diamond.svg';
import { ReactComponent as GMILogoSVG } from 'assets/gmi/GMI-logo.svg';
import { ReactComponent as GMIIconSVG } from 'assets/gmi/GMI-icon.svg';
import { ReactComponent as GMITokenLogoSVG } from 'assets/gmi/GMI-token-logo.svg';
import { ReactComponent as Metamask } from 'assets/metamask/metamask.svg';
import { ReactComponent as MetamaskSVG } from 'assets/metamask/metamask-big.svg';
import { ReactComponent as NRGLogoSVG } from 'assets/nrg/nrg-icon.svg';
import { ReactComponent as ChevronSVG } from 'assets/chevron.svg';
import { ReactComponent as CheckSVG } from 'assets/circle-check.svg';
import { ReactComponent as CloseButtonSVG } from 'assets/close-button.svg';
import { ReactComponent as CopySVG } from 'assets/copy-icon.svg';
import { ReactComponent as DiamondEmojiFill } from 'assets/diamond-emoji-fill.svg';
import { ReactComponent as DiscordSVG } from 'assets/discord.svg';
import { ReactComponent as DiscordSVG_2 } from 'assets/discord-icon.svg';
import { ReactComponent as GreenCheckBoxSVG } from 'assets/green-check-box.svg';
import { ReactComponent as LockIcon } from 'assets/lock.svg';
import { ReactComponent as PersonSVG } from 'assets/person.svg';
import { ReactComponent as RefreshButtonSVG } from 'assets/refresh-button.svg';
import { ReactComponent as RewardGift } from 'assets/reward-gift.svg';
import { ReactComponent as TelegramSVG } from 'assets/telegram-icon.svg';
import { ReactComponent as TimerSVG } from 'assets/timer.svg';
import { ReactComponent as PostsSVG } from 'assets/season2/posts.svg';
import { ReactComponent as ViewsSVG } from 'assets/season2/views.svg';
import { ReactComponent as NRGTokenSVG } from 'assets/tokens/NRG.svg';
import { ReactComponent as ETHTokenSVG } from 'assets/tokens/ETH.svg';
import { ReactComponent as USDTTokenSVG } from 'assets/tokens/USDT.svg';
import { ReactComponent as USDCTokenSVG } from 'assets/tokens/USDC.svg';
import { ReactComponent as TrumpTokenSVG } from 'assets/tokens/Trump.svg';
import { ReactComponent as PoohTokenSVG } from 'assets/tokens/POOH.svg';
import { ReactComponent as TruboTokenSVG } from 'assets/tokens/Turbo.svg';
import { ReactComponent as FlokiTokenSVG } from 'assets/tokens/Floki.svg';
import { ReactComponent as ChainLinkTokenSVG } from 'assets/tokens/Chain-Link.svg';
import { ReactComponent as PEPETokenSVG } from 'assets/tokens/PEPE.svg';
import { ReactComponent as DAITokenSVG } from 'assets/tokens/DAI.svg';
import EthBlackPNG from 'assets/tokens/ETH-BLACK.png';
import UniswapPNG from 'assets/tokens/UNISWAP.png';
import { ReactComponent as BlastSVG } from 'assets/tokens/BLAST.svg';
import BlurPNG from 'assets/tokens/BLUR.png';
import MagicEdenPNG from 'assets/tokens/ME.png';
import PacmoonPNG from 'assets/tokens/PACMOON.png';
import BAYCPNG from 'assets/tokens/BAYC.png';
import MAYCPNG from 'assets/tokens/MAYC.png';
import SHIBPNG from 'assets/tokens/SHIB.png';
import MOGPNG from 'assets/tokens/MOG.png';
import PepePNG from 'assets/tokens/Pepe.png';
import CompoundPNG from 'assets/tokens/Compound.png';
import LidoPNG from 'assets/tokens/Lido.png';
import TheGraphPNG from 'assets/tokens/TheGraph.png';
import { ReactComponent as ENSSVG } from 'assets/tokens/ENS.svg';
import { ReactComponent as GnosisSVG } from 'assets/tokens/Gnosis.svg';
import { ReactComponent as MongCoinSVG } from 'assets/tokens/MongCoin.svg';
import { ReactComponent as KEKECSVG } from 'assets/tokens/KEKEC.svg';
import { ReactComponent as MakerSVG } from 'assets/tokens/Maker.svg';
import { ReactComponent as SandboxSVG } from 'assets/tokens/Sandbox.svg';
import { ReactComponent as SUSHISVG } from 'assets/tokens/SUSHI.svg';
import { ReactComponent as CRVSVG } from 'assets/tokens/CRV.svg';
import BALPNG from 'assets/tokens/BAL.png';
import { ReactComponent as QuirkiesSVG } from 'assets/tokens/Quirkies.svg';
import { ReactComponent as WumbologistsSVG } from 'assets/tokens/Wumbologists.svg';
import NoncoDucksPNG from 'assets/tokens/NoncoDucks.png';
import MANAPNG from 'assets/tokens/MANA.png';
import AAVEPNG from 'assets/tokens/AAVE.png';
import { ReactComponent as LOOKSSVG } from 'assets/tokens/LOOKS.svg';
import { ReactComponent as X2Y2SVG } from 'assets/tokens/X2Y2.svg';
import { ReactComponent as EnergiSwapSVG } from 'assets/season2/energiswapLogo.svg';
import { ReactComponent as UniSwapSVG } from 'assets/season2/uniswapLogo.svg';
import { ReactComponent as XAirdropSVG } from 'assets/season2/x-airdrop.svg';
import { ReactComponent as LiquidityAirdropSVG } from 'assets/season2/liquidity-airdrop.svg';
import { ReactComponent as CreatorAirdropSVG } from 'assets/season2/creator-airdrop.svg';
import { ReactComponent as TraderAirdropSVG } from 'assets/season2/trader-airdrop.svg';
import { ReactComponent as ZealySVG } from 'assets/season2/zealy.svg';
import LandingPageWallet from 'assets/season2/lp-wallet.png';
import LandingPagePoints from 'assets/season2/lp-points.png';
import LandingPageGMI from 'assets/season2/lp-gmi.png';
import LandingPageUserGuide from 'assets/season2/lp-userguide.png';
import { ReactComponent as StaticImage1 } from 'assets/season2/creator-airdrop/static-image1.svg';
import { ReactComponent as StaticImage2 } from 'assets/season2/creator-airdrop/static-image2.svg';
import { ReactComponent as StaticImage3 } from 'assets/season2/creator-airdrop/static-image3.svg';
import { ReactComponent as StaticImage4 } from 'assets/season2/creator-airdrop/static-image4.svg';
import GMIToken from 'assets/gmi/gmi-token.png';
import { ReactComponent as XLogoSVG } from 'assets/x-logo.svg';
import { ReactComponent as CoinsIconSVG } from 'assets/coins-icon.svg';
import { ReactComponent as PlayButtonSVG } from 'assets/season2/play-button.svg';
import { ReactComponent as TokenAirdropSVG } from 'assets/season2/token-airdrop.svg';
import { ReactComponent as NftAirdropSVG } from 'assets/season2/nft-airdrop.svg';
import ZealyAirdropPNG from 'assets/season2/zealy-airdrop.png';
import { ReactComponent as LeaderboardsSVG } from 'assets/season2/leaderboards.svg';
import { ReactComponent as BoostersSVG } from 'assets/season2/boosters.svg';
import { ReactComponent as BackArrowSVG } from 'assets/season2/back-arrow.svg';
import { ReactComponent as GMIDarkGreenSVG } from 'assets/gmi/gmi-dark-green.svg';
import { ReactComponent as OpenSeaSVG } from 'assets/season2/opensea-icon.svg';
import { ReactComponent as NftVolumeAirdropSVG } from 'assets/season2/nft-volume-airdrop.svg';
import { ReactComponent as NftBridingAirdropSVG } from 'assets/season2/nft-bridging-airdrop.svg';
import NftListingAirdropPNG from 'assets/season2/nft-listing-airdrop.png';
import NftPortfolioAirdropPNG from 'assets/season2/nft-portfolio-airdrop.png';
import { ReactComponent as FlashBoltSVG } from 'assets/flash-bolt.svg';
import AmethystsPNG from 'assets/gems/amethysts.png';
import DiamondPNG from 'assets/gems/diamond.png';
import EmeraldPNG from 'assets/gems/emerald.png';
import ManaPNG from 'assets/gems/mana.png';
import MoonstonePNG from 'assets/gems/moonstone.png';
import QuartzPNG from 'assets/gems/quartz.png';
import RubyPNG from 'assets/gems/ruby.png';
import SapphirePNG from 'assets/gems/sapphire.png';
import TopazPNG from 'assets/gems/topaz.png';
import PearlPNG from 'assets/gems/pearl.png';
import GoldPNG from 'assets/gems/gold.png';
import AetherPNG from 'assets/gems/aether.png';
import PlatinumPNG from 'assets/gems/platinum.png';
import PowerTweetAirdropPNG from 'assets/powertweeticon.png';
import SHIBUPNG from 'assets/tokens/SHIBU.png';
import { ReactComponent as MemeCreatorSVG } from 'assets/meme-creator.svg';
import ThumbCrossed from 'assets/emojis/thumb-crossed.png';
import CRYPTO_CHICKS_PNG from 'assets/tokens/CCH.png';
import ReferralContestPNG from 'assets/referral-contest.png';

export const InfoIcon = styled((props) => <InfoOutlinedIcon {...props} />)(
  ({ theme, width, height }) => ({
    width: width ?? 20,
    height: height ?? 20,

    '& path': {
      fill: theme.palette.icon.secondary,
    },
  }),
);

export const ApedIcon = ({ width, height }) => (
  <img src={Aped} width={width ?? 28} height={height ?? 28} />
);

export const DiamondIcon = styled((props) => <DiamondSVG {...props} />)(({ theme, size = 24 }) => ({
  width: size,
  height: size,
  verticalAlign: 'middle',
  margin: theme.spacing(0, 0.5),
  marginTop: -2,
}));

export const ETHStackerIcon = ({ width, height }) => (
  <img src={ETHStacker} width={width ?? 28} height={height ?? 28} />
);

export const EarlyDegenIcon = ({ width, height }) => (
  <img src={EarlyDegen} width={width ?? 28} height={height ?? 28} />
);

export const GreenHeartIcon = ({ width, height }) => (
  <img src={GreenHeart} width={width ?? 28} height={height ?? 28} />
);

export const SpreadWordIcon = ({ width, height, ...props }) => (
  <img src={SpreadWord} width={width ?? 28} height={height ?? 28} {...props} />
);

export const LooksRareIcon = ({ width, height }) => (
  <img src={LooksRare} width={width ?? 28} height={height ?? 28} />
);

export const MoneyFaceIcon = ({ width, height }) => (
  <img src={MoneyFace} width={width ?? 18} height={height ?? 18} />
);

export const ShipIcon = ({ width, height }) => (
  <img src={Ship} width={width ?? 28} height={height ?? 28} />
);

export const SparkIcon = ({ width, height }) => (
  <img src={Spark} width={width ?? 28} height={height ?? 28} />
);

export const SushiIcon = ({ width, height }) => (
  <img src={Sushi} width={width ?? 28} height={height ?? 28} />
);

export const BirdIcon = ({ width, height }) => (
  <img src={Bird} width={width ?? 28} height={height ?? 28} />
);

export const ThumbCrossedIcon = ({ width, height }) => (
  <img src={ThumbCrossed} width={width ?? 28} height={height ?? 28} />
);

export const UnicornIcon = ({ width, height }) => (
  <img src={Unicorn} width={width ?? 28} height={height ?? 28} />
);

export const X2Y2Icon = ({ width, height }) => (
  <img src={X2Y2} width={width ?? 28} height={height ?? 28} />
);

export const SmilingHeartIcon = ({ width, height }) => (
  <img src={SmilingHeart} width={width ?? 64} height={height ?? 64} />
);

export const MoneyBagIcon = ({ width, height }) => (
  <img src={MoneyBag} width={width ?? 64} height={height ?? 64} />
);

export const BronzeMedalIcon = ({ width, height }) => (
  <img src={BronzeMedal} width={width ?? 64} height={height ?? 64} />
);

export const SilverMedalIcon = ({ width, height }) => (
  <img src={SilverMedal} width={width ?? 64} height={height ?? 64} />
);

export const GoldMedalIcon = ({ width, height }) => (
  <img src={GoldMedal} width={width ?? 64} height={height ?? 64} />
);

export const HeartRibbonIcon = ({ width, height }) => (
  <img src={HeartRibbon} width={width ?? 28} height={height ?? 28} />
);

export const CongratsFaceIcon = ({ width, height }) => (
  <img src={CongratsFace} width={width ?? 70} height={height ?? 70} />
);

export const TrophyIcon = ({ width, height }) => (
  <img src={Trophy} width={width ?? 18} height={height ?? 18} />
);

export const ThinkFaceIcon = ({ width, height }) => (
  <img src={ThinkFace} width={width ?? 62} height={height ?? 62} />
);

export const SportsMedalIcon = ({ width, height }) => (
  <img src={SportsMedal} width={width ?? 18} height={height ?? 18} />
);

export const MoneyWingsIcon = ({ width, height }) => (
  <img src={MoneyWings} width={width ?? 22} height={height ?? 22} />
);

export const SparklesIcon = ({ width, height }) => (
  <img src={Sparkles} width={width ?? 22} height={height ?? 22} />
);

export const PouchIcon = ({ width, height }) => (
  <img src={PouchPNG} width={width ?? 22} height={height ?? 22} />
);

export const GMI = styled((props) => <GMILogoSVG {...props} />)(({ theme }) => ({
  height: 34,
  width: 57,

  '& .logo-theme': {
    fill: theme.palette.text.main,
  },
}));

export const GMIIcon = styled((props) => <GMIIconSVG {...props} />)(({ theme }) => ({
  height: 48,
  width: 41,

  '& .logo-theme': {
    fill: theme.palette.text.main,
  },
}));

export const GMITokenLogo = styled((props) => <GMITokenLogoSVG {...props} />)(
  ({ width, height }) => ({
    height: height ?? 32,
    width: width ?? 32,
  }),
);

export const MetamaskIcon = styled((props) => <Metamask {...props} />)(
  ({ width, height, margin }) => ({
    width: width ?? 25,
    height: height ?? 25,
    marginLeft: margin ?? 5,
  }),
);

export const MetamaskBigIcon = styled((props) => <MetamaskSVG {...props} />)(
  ({ width, height }) => ({
    width: width ?? 105,
    height: height ?? 105,
  }),
);

export const NRGIcon = styled((props) => <NRGLogoSVG {...props} />)(({ width, height }) => ({
  width: width ?? 24,
  height: height ?? 24,
  verticalAlign: 'middle',
}));

export const ChevronIcon = styled((props) => <ChevronSVG {...props} />)(({ width, height }) => ({
  width: width ?? 12,
  height: height ?? 12,
}));

export const GreenCheckIcon = styled((props) => <CheckSVG {...props} />)(({ width, height }) => ({
  width: width ?? 40,
  height: height ?? 40,
}));

export const CloseButton = styled((props) => <CloseButtonSVG {...props} />)(
  ({ width, height }) => ({
    width: width ?? 40,
    height: height ?? 40,
  }),
);

export const CopyIcon = styled((props) => <CopySVG {...props} />)(({ width, height }) => ({
  width: width ?? 32,
  height: height ?? 32,
}));

export const DiamondFillSpace = styled((props) => <DiamondEmojiFill {...props} />)(
  ({ width, height }) => ({
    width: width ?? 17,
    height: height ?? 17,
  }),
);

export const DiscordIcon = styled((props) => <DiscordSVG {...props} />)(({ width, height }) => ({
  width: width ?? 22,
  height: height ?? 22,
}));

export const DiscordIcon_2 = styled((props) => <DiscordSVG_2 {...props} />)(
  ({ width, height, color }) => ({
    width: width ?? 22,
    height: height ?? 22,

    '& > path': {
      fill: color ?? '#A2ADB8',
    },
  }),
);

export const GreenCheckBox = styled((props) => <GreenCheckBoxSVG {...props} />)(
  ({ width, height }) => ({
    width: width ?? 40,
    height: height ?? 40,
  }),
);

export const SmallLockIcon = styled((props) => <LockIcon {...props} />)(
  ({ theme, width, height }) => ({
    width: width ?? 24,
    height: height ?? 24,

    '& path': {
      fill: theme.palette.text.main,
    },
  }),
);

export const PersonIcon = styled((props) => <PersonSVG {...props} />)(
  ({ theme, width, height }) => ({
    width: width ?? 24,
    height: height ?? 24,

    '& path': {
      fill: theme.palette.text.main,
    },
  }),
);

export const RefreshButton = styled((props) => <RefreshButtonSVG {...props} />)(
  ({ width, height }) => ({
    width: width ?? 40,
    height: height ?? 40,
  }),
);

export const RewardGiftIcon = styled((props) => <RewardGift {...props} />)(({ width, height }) => ({
  width: width ?? 24,
  height: height ?? 24,
}));

export const TelegramIcon = styled((props) => <TelegramSVG {...props} />)(
  ({ width, height, color }) => ({
    width: width ?? 22,
    height: height ?? 22,

    '& > path': {
      fill: color ?? '#A2ADB8',
    },
  }),
);

export const TimerIcon = styled((props) => <TimerSVG {...props} />)(({ theme, width, height }) => ({
  width: width ?? 24,
  height: height ?? 24,

  '& path': {
    fill: theme.palette.text.main,
  },
}));

export const PostsIcon = styled((props) => <PostsSVG {...props} />)(
  ({ width = 18, height = 18 }) => ({
    width,
    height,
    verticalAlign: 'middle',
  }),
);

export const ViewsIcon = styled((props) => <ViewsSVG {...props} />)(
  ({ width = 24, height = 24 }) => ({
    width,
    height,
    verticalAlign: 'middle',
  }),
);

export const NRGTokenIcon = styled((props) => <NRGTokenSVG {...props} />)(
  ({ width = 24, height = 24 }) => ({
    width,
    height,
    verticalAlign: 'middle',
  }),
);

export const EthBlackIcon = ({ width = 104, height = 104 }) => (
  <img src={EthBlackPNG} width={width} height={height} />
);

export const UniswapIcon = ({ width = 104, height = 104 }) => (
  <img src={UniswapPNG} width={width} height={height} />
);

export const BlurIcon = ({ width = 104, height = 104 }) => (
  <img src={BlurPNG} width={width} height={height} />
);

export const MagicEdenIcon = ({ width = 104, height = 104 }) => (
  <img src={MagicEdenPNG} style={{ borderRadius: '50%' }} width={width} height={height} />
);

export const BlastIcon = styled((props) => <BlastSVG {...props} />)(
  ({ width = 104, height = 104 }) => ({
    width,
    height,
    verticalAlign: 'middle',
  }),
);

export const PacmoonIcon = ({ width = 104, height = 104 }) => (
  <img src={PacmoonPNG} width={width} height={height} />
);

export const BAYCIcon = ({ width = 104, height = 104 }) => (
  <img src={BAYCPNG} width={width} height={height} />
);

export const MAYCIcon = ({ width = 104, height = 104 }) => (
  <img src={MAYCPNG} width={width} height={height} />
);

export const CryptoChicksIcon = ({ width = 104, height = 104 }) => (
  <img src={CRYPTO_CHICKS_PNG} width={width} height={height} style={{ borderRadius: '50%' }} />
);

export const NoncoDucksIcon = ({ width = 104, height = 104 }) => (
  <img src={NoncoDucksPNG} width={width} height={height} />
);

export const SHIBIcon = ({ width = 104, height = 104 }) => (
  <img src={SHIBPNG} width={width} height={height} />
);

export const MOGIcon = ({ width = 104, height = 104 }) => (
  <img src={MOGPNG} width={width} height={height} />
);

export const PepeIcon = ({ width = 104, height = 104 }) => (
  <img src={PepePNG} width={width} height={height} />
);

export const CompoundIcon = ({ width = 104, height = 104 }) => (
  <img src={CompoundPNG} width={width} height={height} />
);

export const LidoIcon = ({ width = 104, height = 104 }) => (
  <img src={LidoPNG} width={width} height={height} />
);

export const TheGraphIcon = ({ width = 104, height = 104 }) => (
  <img src={TheGraphPNG} width={width} height={height} />
);

export const MANAIcon = ({ width = 104, height = 104 }) => (
  <img src={MANAPNG} width={width} height={height} />
);

export const AAVEIcon = ({ width = 104, height = 104 }) => (
  <img src={AAVEPNG} width={width} height={height} />
);

export const LOOKSIcon = styled((props) => <LOOKSSVG {...props} />)(
  ({ width = 104, height = 104 }) => ({
    width,
    height,
    verticalAlign: 'middle',
  }),
);

export const SHIBUIcon = ({ width = 104, height = 104 }) => (
  <img src={SHIBUPNG} width={width} height={height} />
);

export const EnsIcon = styled((props) => <ENSSVG {...props} />)(
  ({ width = 104, height = 104 }) => ({
    width,
    height,
    verticalAlign: 'middle',
  }),
);

export const GnosisIcon = styled((props) => <GnosisSVG {...props} />)(
  ({ width = 104, height = 104 }) => ({
    width,
    height,
    verticalAlign: 'middle',
  }),
);

export const MongCoinIcon = styled((props) => <MongCoinSVG {...props} />)(
  ({ width = 104, height = 104 }) => ({
    width,
    height,
    verticalAlign: 'middle',
  }),
);

export const X2Y2TokenIcon = styled((props) => <X2Y2SVG {...props} />)(
  ({ width = 104, height = 104 }) => ({
    width,
    height,
    verticalAlign: 'middle',
  }),
);

export const KEKECIcon = styled((props) => <KEKECSVG {...props} />)(
  ({ width = 104, height = 104 }) => ({
    width,
    height,
    verticalAlign: 'middle',
  }),
);

export const MakerIcon = styled((props) => <MakerSVG {...props} />)(
  ({ width = 104, height = 104 }) => ({
    width,
    height,
    verticalAlign: 'middle',
  }),
);

export const SandboxIcon = styled((props) => <SandboxSVG {...props} />)(
  ({ width = 104, height = 104 }) => ({
    width,
    height,
    verticalAlign: 'middle',
  }),
);

export const SUSHIIcon = styled((props) => <SUSHISVG {...props} />)(
  ({ width = 104, height = 104 }) => ({
    width,
    height,
    verticalAlign: 'middle',
  }),
);

export const CRVIcon = styled((props) => <CRVSVG {...props} />)(
  ({ width = 104, height = 104 }) => ({
    width,
    height,
    verticalAlign: 'middle',
  }),
);

export const BALIcon = ({ width = 104, height = 104 }) => (
  <img src={BALPNG} width={width} height={height} />
);

export const WumbologistsIcon = styled((props) => <WumbologistsSVG {...props} />)(
  ({ width = 104, height = 104 }) => ({
    width,
    height,
    verticalAlign: 'middle',
  }),
);

export const QuirkiesIcon = styled((props) => <QuirkiesSVG {...props} />)(
  ({ width = 104, height = 104 }) => ({
    width,
    height,
    verticalAlign: 'middle',
  }),
);

export const ETHTokenIcon = styled((props) => <ETHTokenSVG {...props} />)(
  ({ width = 24, height = 24 }) => ({
    width,
    height,
    verticalAlign: 'middle',
  }),
);

export const USDTTokenIcon = styled((props) => <USDTTokenSVG {...props} />)(
  ({ width = 24, height = 24 }) => ({
    width,
    height,
    verticalAlign: 'middle',
  }),
);

export const USDCTokenIcon = styled((props) => <USDCTokenSVG {...props} />)(
  ({ width = 24, height = 24 }) => ({
    width,
    height,
    verticalAlign: 'middle',
  }),
);

export const TrumpTokenIcon = styled((props) => <TrumpTokenSVG {...props} />)(
  ({ width = 24, height = 24 }) => ({
    width,
    height,
    verticalAlign: 'middle',
  }),
);

export const TurboTokenIcon = styled((props) => <TruboTokenSVG {...props} />)(
  ({ width = 24, height = 24 }) => ({
    width,
    height,
    verticalAlign: 'middle',
  }),
);

export const PoohTokenIcon = styled((props) => <PoohTokenSVG {...props} />)(
  ({ width = 24, height = 24 }) => ({
    width,
    height,
    verticalAlign: 'middle',
  }),
);

export const FlokiTokenIcon = styled((props) => <FlokiTokenSVG {...props} />)(
  ({ width = 24, height = 24 }) => ({
    width,
    height,
    verticalAlign: 'middle',
  }),
);

export const ChainLinkTokenIcon = styled((props) => <ChainLinkTokenSVG {...props} />)(
  ({ width = 24, height = 24 }) => ({
    width,
    height,
    verticalAlign: 'middle',
  }),
);

export const PEPETokenIcon = styled((props) => <PEPETokenSVG {...props} />)(
  ({ width = 24, height = 24 }) => ({
    width,
    height,
    verticalAlign: 'middle',
  }),
);

export const EnergiSwapLogo = styled((props) => <EnergiSwapSVG {...props} />)(
  ({ width, height = 46 }) => ({
    width,
    height,
    verticalAlign: 'middle',
  }),
);

export const UniSwapLogo = styled((props) => <UniSwapSVG {...props} />)(
  ({ width, height = 46 }) => ({
    width,
    height,
    verticalAlign: 'middle',
  }),
);

export const DAITokenIcon = styled((props) => <DAITokenSVG {...props} />)(
  ({ width = 24, height = 24 }) => ({
    width,
    height,
    verticalAlign: 'middle',
  }),
);

export const XAirdropIcon = styled((props) => <XAirdropSVG {...props} />)(
  ({ width = 64, height = 64 }) => ({
    width,
    height,
    verticalAlign: 'middle',
  }),
);

export const LiquidityAirdropIcon = styled((props) => <LiquidityAirdropSVG {...props} />)(
  ({ width = 76, height = 64 }) => ({
    width,
    height,
    verticalAlign: 'middle',
  }),
);

export const CreatorAirdropIcon = styled((props) => <CreatorAirdropSVG {...props} />)(
  ({ width = 64, height = 64 }) => ({
    width,
    height,
    verticalAlign: 'middle',
  }),
);

export const TraderAirdropIcon = styled((props) => <TraderAirdropSVG {...props} />)(
  ({ width = 84, height = 64 }) => ({
    width,
    height,
    verticalAlign: 'middle',
  }),
);

export const ZealyIcon = styled((props) => <ZealySVG {...props} />)(
  ({ width = 24, height = 24 }) => ({
    width,
    height,
    verticalAlign: 'middle',
  }),
);

export const LandingPageWalletIcon = ({ width, height }) => (
  <img src={LandingPageWallet} width={width ?? 22} height={height ?? 22} />
);

export const LandingPagePointsIcon = ({ width, height }) => (
  <img src={LandingPagePoints} width={width ?? 22} height={height ?? 22} />
);

export const LandingPageGMIIcon = ({ width, height }) => (
  <img src={LandingPageGMI} width={width ?? 22} height={height ?? 22} />
);

export const LandingPageUserGuideIcon = ({ width, height }) => (
  <img src={LandingPageUserGuide} width={width ?? 22} height={height ?? 22} />
);

export const CreatorStaticImage1 = styled((props) => <StaticImage1 {...props} />)(
  ({ width = 260, height = '100%' }) => ({
    width,
    height,
    verticalAlign: 'middle',
  }),
);

export const CreatorStaticImage2 = styled((props) => <StaticImage2 {...props} />)(
  ({ width = 260, height = '100%' }) => ({
    width,
    height,
    verticalAlign: 'middle',
  }),
);

export const CreatorStaticImage3 = styled((props) => <StaticImage3 {...props} />)(
  ({ width = 260, height = '100%' }) => ({
    width,
    height,
    verticalAlign: 'middle',
  }),
);

export const CreatorStaticImage4 = styled((props) => <StaticImage4 {...props} />)(
  ({ width = 260, height = '100%' }) => ({
    width,
    height,
    verticalAlign: 'middle',
  }),
);

export const GMITokenImage = ({ width = 184, height = 184 }) => (
  <img src={GMIToken} width={width} height={height} />
);

export const XLogo = styled((props) => <XLogoSVG {...props} />)(({ width, height, color }) => ({
  height: height ?? 32,
  width: width ?? 32,

  '& > path': {
    fill: color ?? '#141619',
  },
}));

export const CoinsIcon = styled((props) => <CoinsIconSVG {...props} />)(
  ({ width, height, color }) => ({
    width: width ?? 32,
    height: height ?? 32,

    '& > path': {
      fill: color ?? '#141619',
    },
  }),
);

export const PlayButton = styled((props) => <PlayButtonSVG {...props} />)(
  ({ width = 300, height = 100 }) => ({
    width,
    height,
  }),
);

export const TokenAirdropIcon = styled((props) => <TokenAirdropSVG {...props} />)(
  ({ width = '100%', height = '100%' }) => ({
    width,
    height,
    verticalAlign: 'middle',
  }),
);

export const NftAirdropIcon = styled((props) => <NftAirdropSVG {...props} />)(
  ({ width = '100%', height = '100%' }) => ({
    width,
    height,
    verticalAlign: 'middle',
  }),
);

export const ZealyAirdropIcon = ({ width = 110, height = '100%' }) => (
  <img src={ZealyAirdropPNG} width={width} height={height} />
);

export const LeaderboardsIcon = styled((props) => <LeaderboardsSVG {...props} />)(
  ({ width = '100%', height = '100%' }) => ({
    width,
    height,
    verticalAlign: 'middle',
  }),
);

export const BoostersIcon = styled((props) => <BoostersSVG {...props} />)(
  ({ width = '100%', height = '100%' }) => ({
    width,
    height,
    verticalAlign: 'middle',
  }),
);

export const BackArrow = styled((props) => <BackArrowSVG {...props} />)(
  ({ width = '100%', height = '100%' }) => ({
    width,
    height,
    verticalAlign: 'middle',
  }),
);

export const GMIDarkGreenIcon = styled((props) => <GMIDarkGreenSVG {...props} />)(
  ({ width = 20, height = 20 }) => ({
    width,
    height,
    verticalAlign: 'middle',
  }),
);

export const OpenSeaIcon = styled((props) => <OpenSeaSVG {...props} />)(
  ({ width = 104, height = 104 }) => ({
    width,
    height,
    verticalAlign: 'middle',
  }),
);

export const NftVolumeAirdropIcon = styled((props) => <NftVolumeAirdropSVG {...props} />)(
  ({ width = 'auto', height = '100%' }) => ({
    width,
    height,
    verticalAlign: 'middle',
  }),
);

export const NftBridingAirdropIcon = styled((props) => <NftBridingAirdropSVG {...props} />)(
  ({ width = 'auto', height = '100%' }) => ({
    width,
    height,
    verticalAlign: 'middle',
  }),
);

export const NftListingAirdropIcon = ({ width = 'auto', height = '100%' }) => (
  <img src={NftListingAirdropPNG} width={width} height={height} />
);

export const NftPortfolioAirdropIcon = ({ width = 'auto', height = '100%' }) => (
  <img src={NftPortfolioAirdropPNG} width={width} height={height} />
);

export const FlashBoltIcon = styled((props) => <FlashBoltSVG {...props} />)(
  ({ width = 32, height = 32 }) => ({
    width,
    height,
    verticalAlign: 'middle',
  }),
);

export const PowerTweetAirdropIcon = ({ width = 'auto', height = '100%' }) => (
  <img src={PowerTweetAirdropPNG} width={width} height={height} />
);

export const AmethystsGemIcon = ({ width = 24, height = 24 }) => (
  <img src={AmethystsPNG} width={width} height={height} />
);

export const DiamondGemIcon = ({ width = 24, height = 24 }) => (
  <img src={DiamondPNG} width={width} height={height} />
);

export const EmeraldGemIcon = ({ width = 24, height = 24 }) => (
  <img src={EmeraldPNG} width={width} height={height} />
);

export const ManaGemIcon = ({ width = 24, height = 24 }) => (
  <img src={ManaPNG} width={width} height={height} />
);

export const MoonstoneGemIcon = ({ width = 24, height = 44 }) => (
  <img src={MoonstonePNG} width={width} height={height} />
);

export const QuartzGemIcon = ({ width = 24, height = 24 }) => (
  <img src={QuartzPNG} width={width} height={height} />
);

export const RubyGemIcon = ({ width = 24, height = 24 }) => (
  <img src={RubyPNG} width={width} height={height} />
);

export const SapphireGemIcon = ({ width = 24, height = 24 }) => (
  <img src={SapphirePNG} width={width} height={height} />
);

export const TopazGemIcon = ({ width = 24, height = 24 }) => (
  <img src={TopazPNG} width={width} height={height} />
);

export const PearlGemIcon = ({ width = 24, height = 24 }) => (
  <img src={PearlPNG} width={width} height={height} />
);

export const AetherGemIcon = ({ width = 24, height = 24 }) => (
  <img src={AetherPNG} width={width} height={height} />
);

export const GoldIcon = ({ width = 24, height = 18 }) => (
  <img src={GoldPNG} width={width} height={height} />
);

export const PlatinumIcon = ({ width = 24, height = 18 }) => (
  <img src={PlatinumPNG} width={width} height={height} />
);

export const MemeCreatorIcon = styled((props) => <MemeCreatorSVG {...props} />)(
  ({ width = '100%', height = '100%' }) => ({
    width,
    height,
    verticalAlign: 'middle',
  }),
);

export const ReferralContestIcon = ({ width = 24, height = 24 }) => (
  <img src={ReferralContestPNG} width={width} height={height} />
);

export const MedalGold = ({ className = '', size = 26 }) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 24 24"
    fill="none"
    stroke="currentColor"
    strokeWidth="2"
    strokeLinecap="round"
    strokeLinejoin="round"
    className={className}
  >
    <path d="m2 4 3 12h14l3-12-6 7-4-7-4 7-6-7zm3 16h14" />
  </svg>
);

export const MedalSilver = ({ className = '', size = 24 }) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 24 24"
    fill="none"
    stroke="currentColor"
    strokeWidth="2"
    strokeLinecap="round"
    strokeLinejoin="round"
    className={className}
  >
    <path d="M6 9H4.5a2.5 2.5 0 0 1 0-5H6" />
    <path d="M18 9h1.5a2.5 2.5 0 0 0 0-5H18" />
    <path d="M4 22h16" />
    <path d="M10 14.66V17c0 .55-.47.98-.97 1.21C7.85 18.75 7 20.24 7 22" />
    <path d="M14 14.66V17c0 .55.47.98.97 1.21C16.15 18.75 17 20.24 17 22" />
    <path d="M18 2H6v7a6 6 0 0 0 12 0V2Z" />
  </svg>
);

export const MedalBronze = ({ className = '', size = 22 }) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 24 24"
    fill="none"
    stroke="currentColor"
    strokeWidth="2"
    strokeLinecap="round"
    strokeLinejoin="round"
    className={className}
  >
    <circle cx="12" cy="8" r="7" />
    <path d="M8.21 13.89L7 23l5-3 5 3-1.21-9.12" />
  </svg>
);

export {
  InstagramIcon,
  TwitterIcon,
  RedditIcon,
  YouTubeIcon,
  FacebookIcon,
  WhatsAppIcon,
  LinkedInIcon,
};
