import { ReferralApi } from 'clientSDK';

const referralApi = new ReferralApi();

export default {
  CreateReferral: async (body) => await referralApi.CreateReferral(body),

  getReferrals: async (season = 0) => await referralApi.getReferrals(season),

  getAirdropBonuses: async () => await referralApi.getAirdropBonuses(), // S1

  getSpreadTheWordBonus: async () => await referralApi.getSpreadTheWordBonus(),

  getNRGDiamondBonus: async () => await referralApi.getNRGDiamondBonus(),

  activateApedInBonus: async (body) => await referralApi.activateApedInBonus(body),

  getReferralStats: async ({ skip, limit }) => await referralApi.getReferralStats({ skip, limit }),

  getSingleReferralStats: async () => await referralApi.getSingleReferralStats(),
};
