import { ApiClient } from '../ApiClient';

export class ReferralApi {
  /**
   * Constructs a new ReferralApi.
   * @alias module:api/ReferralApi
   * @class
   * @param {module:ApiClient} [apiClient] Optional API client implementation to use,
   * default to {@link module:ApiClient#instance} if unspecified.
   */
  constructor(apiClient) {
    this.apiClient = apiClient || ApiClient.generalApiInstance;
  }

  /**
   * Add referral
   *
   * @param {{referralAddress: string, phase: number}} body
   */
  async CreateReferral(body) {
    const headers = { Authorization: `Bearer ${localStorage.token}` };
    return await this.apiClient.callApi('/referrals', 'post', {}, {}, null, headers, body);
  }

  /**
   *  Get referral details by address
   */
  async getReferrals(season = 0) {
    const headers = { Authorization: `Bearer ${localStorage.token}` };
    return await this.apiClient.callApi(
      '/referrals/{address}',
      'get',
      {},
      { season },
      null,
      headers,
    );
  }

  /**
   *  Get Airdrop Bonuses for season 1
   */
  async getAirdropBonuses() {
    const headers = { Authorization: `Bearer ${localStorage.token}` };
    return await this.apiClient.callApi('/user/{address}/s1Airdrop', 'get', {}, {}, null, headers);
  }

  /**
   *  Get Spread The Word Bonus
   */
  async getSpreadTheWordBonus() {
    const headers = { Authorization: `Bearer ${localStorage.token}` };
    return await this.apiClient.callApi(
      '/user/{address}/spreadtheword',
      'get',
      {},
      {},
      null,
      headers,
    );
  }

  /**
   *  Get NRG Diamond Bonus
   */
  async getNRGDiamondBonus() {
    const headers = { Authorization: `Bearer ${localStorage.token}` };
    return await this.apiClient.callApi(
      '/user/{address}/nrgdiamondhands',
      'get',
      {},
      {},
      null,
      headers,
    );
  }

  /**
   *  Activate Aped In Bonus
   *
   * @param {{discord, telegram, twitter}} body User social media
   */
  async activateApedInBonus(body) {
    const headers = { Authorization: `Bearer ${localStorage.token}` };
    return await this.apiClient.callApi(
      '/user/activateApedInbonus',
      'post',
      {},
      {},
      null,
      headers,
      body,
    );
  }

  async getReferralStats({ skip, limit }) {
    const headers = localStorage.token && { authorization: `${localStorage.token}` };
    return await this.apiClient.callApi(
      '/referrals/stats',
      'get',
      {},
      { skip, limit },
      null,
      headers,
    );
  }

  async getSingleReferralStats() {
    const headers = { Authorization: `Bearer ${localStorage.token}` };
    return await this.apiClient.callApi('/referrals/stats/account', 'get', {}, {}, null, headers);
  }
}
