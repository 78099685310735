import React from 'react';
import { useMediaQuery } from '@mui/material';
import { StandardButton } from './Button';
import MetamaskNotInstalledModal from 'components/MetamaskNotInstalledModal/MetamaskNotInstalledModal';
import { MetamaskIcon } from 'components/ImageComponent';
import { Text } from 'components/Text';
import { useWallet } from 'context/WalletContext';

export const ConnectButton = ({ width, height, disabled, onClick, children, styleProps = {} }) => {
  const { walletModalOpen, connectWallet, closeModal } = useWallet();

  const connect = async () => {
    await connectWallet();
  };

  return (
    <>
      <StandardButton
        width={width ?? '100%'}
        height={height ?? 48}
        disabled={disabled}
        onClick={onClick ?? connect}
        sx={{ p: 0, ...styleProps }}
      >
        {children ?? 'Connect to Metamask'}
      </StandardButton>
      <MetamaskNotInstalledModal isOpen={walletModalOpen} onDismiss={closeModal} />
    </>
  );
};

export const NavBarConnectButton = () => {
  const below480 = useMediaQuery('(max-width:480px)');

  return (
    <ConnectButton width={below480 ? 133 : 215} height={40}>
      {below480 ? (
        <>
          Connect <MetamaskIcon />
        </>
      ) : null}
    </ConnectButton>
  );
};

export const BigPanelConnectButton = () => {
  const below480 = useMediaQuery('(max-width:480px)');

  return (
    <ConnectButton height={64}>
      <Text fs={20} fw={600} lh={28}>
        {below480 ? 'Connect Metamask' : 'Connect Metamask to invite friends'}
      </Text>
    </ConnectButton>
  );
};
