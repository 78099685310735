import React, { useEffect } from 'react';
import { useStateIfMounted } from 'use-state-if-mounted';
import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  IconButton,
  Typography,
  styled,
  useMediaQuery,
} from '@mui/material';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';

import { formatAddress } from 'utils/address';
import useNotification from 'hooks/useNotification';
import { useWallet } from 'context/WalletContext';

import Modal from 'components/Modal';
import { FlexCenter, ToolTip } from 'components/StyledComponents';
import Blockie from 'components/Blockie';
import WalletLinkingVideo from 'assets/season2/wallet-linking.webm';

const Container = styled(Box)(({ theme }) => ({
  padding: theme.spacing(3),
  textAlign: 'left',
  width: '100%',
}));

const StyledTableBody = styled(TableBody)(({ theme }) => ({
  background: theme.palette.background.default,
}));

const Heading = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.background.filter,
  padding: theme.spacing(2.5, 4.5),
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  borderRadius: theme.spacing(2),
  marginBottom: theme.spacing(1),
  width: '100%',

  '@media (max-width: 600px)': {
    padding: theme.spacing(2.5, 3),
  },
}));

const modalContentStyles = {
  maxHeight: '90vh',
  overflow: 'hidden',
};

const LinkedWalletsModal = ({ onClose }) => {
  const [linkedWallets, setLinkedWallets] = useStateIfMounted([]);
  const { wallet } = useWallet();
  const showNotification = useNotification();
  const isSmallScreen = useMediaQuery('(max-width:480px)');

  useEffect(() => {
    setLinkedWallets(JSON.parse(localStorage.getItem('linked_wallets')));
  }, []);

  const handleCopyToClipboard = (address) => {
    navigator.clipboard.writeText(address);
    showNotification({
      type: 'success',
      message: 'Address copied',
    });
  };

  return (
    <Modal title="Linked Wallets" isOpen={true} onDismiss={onClose}>
      <Container>
        <Heading>
          <Typography fontSize={18} fontWeight={500}>
            Your linked wallets
          </Typography>
        </Heading>
        <FlexCenter>
          <Box sx={modalContentStyles}>
            <TableContainer>
              <Table>
                <StyledTableBody>
                  {linkedWallets.map((address, index) => (
                    <TableRow key={index}>
                      <TableCell
                        sx={{
                          display: 'flex',
                          alignItems: 'center',
                          color: address === wallet ? '#00E676' : 'inherit',
                        }}
                      >
                        <Blockie size={4} marginRight={1} customAddress={wallet} />
                        {isSmallScreen ? formatAddress(wallet, 5, 3) : wallet}
                      </TableCell>
                      <TableCell>
                        <ToolTip title="Copy Address" placement="top">
                          <IconButton
                            onClick={() => handleCopyToClipboard(wallet)}
                            sx={{ padding: isSmallScreen ? '0px' : '5px' }}
                          >
                            <ContentCopyIcon />
                          </IconButton>
                        </ToolTip>
                      </TableCell>
                    </TableRow>
                  ))}
                </StyledTableBody>
              </Table>
            </TableContainer>
          </Box>
        </FlexCenter>
        <br />
        <Heading>
          <Typography fontSize={18} fontWeight={500}>
            How It Works
          </Typography>
        </Heading>
        <FlexCenter>
          <video muted autoPlay loop>
            <source src={WalletLinkingVideo} type="video/webm" />
          </video>
        </FlexCenter>
      </Container>
    </Modal>
  );
};

export default LinkedWalletsModal;
