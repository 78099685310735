import { useContext } from 'react';
import { Avatar, Box, List, ListItem, styled } from '@mui/material';

import { DashboardContext } from 'context/DashboardContext';
import { useWallet } from 'context/WalletContext';
import { getXOauthUrl } from 'utils/x';

import { Text } from 'components/Text';
import Blockie from 'components/Blockie';
import { StandardButton, ConnectButton } from 'components/Button';

const SingleLeaderBoardItem = styled(ListItem)(({ theme, active }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  borderBottom: `1px solid ${theme.palette.border.separator}`,
  padding: theme.spacing(2.4, 2),
  height: 82,
  backgroundColor: active ? 'rgba(0, 230, 118, 0.08)' : 'transparent',
}));

const CenteredBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  height: '100%',
  padding: theme.spacing(2),
  textAlign: 'center',
}));

const DataRow = ({ item, index, offset }) => {
  const { id, userAddress, totalPoints, User } = item;
  const { twitterUser, avatar } = User;
  const itsMe = userAddress === localStorage.getItem('linked_main_wallet');

  return (
    <SingleLeaderBoardItem key={id} active={itsMe ? 'active' : ''}>
      <Box display="flex" alignItems="center">
        {avatar ? (
          <Avatar src={avatar} alt={twitterUser} sx={{ height: 34, width: 34 }} />
        ) : (
          <Blockie size={4} customAddress={userAddress} />
        )}
        <Box marginLeft={1} maxHeight="40px" display="flex" flexDirection="column" gap={0}>
          <Text fs={16} fw={600} lh="24px" color={itsMe ? 'active' : 'default'}>
            {itsMe ? 'YOU' : twitterUser}
          </Text>
          <Text fs={12} fw={500} lh="16" component="span" align="left">
            {offset + index + 1}
          </Text>
        </Box>
      </Box>
      <Box>
        <Text fs={20} fw={600}>
          {Number(totalPoints).toFixed(0)} Amethysts
        </Text>
      </Box>
    </SingleLeaderBoardItem>
  );
};

const Leaderboard = () => {
  const { state } = useContext(DashboardContext);
  const { isAuthenticated, twitterUser, wallet } = useWallet();

  const xLink = getXOauthUrl(wallet);
  const { aroundUsers: leaderboard = [], offset = 0 } = state.data?.xLeaderboard ?? {};

  if (!isAuthenticated) {
    return (
      <CenteredBox>
        <ConnectButton height={40} />
      </CenteredBox>
    );
  }

  if (!twitterUser) {
    return (
      <CenteredBox>
        <StandardButton href={xLink}>Sign in with 𝕏</StandardButton>
      </CenteredBox>
    );
  }

  return (
    <List sx={{ marginTop: 0, paddingTop: 0 }}>
      {leaderboard.map((item, index) => (
        <DataRow key={index} item={item} index={index} offset={offset} />
      ))}
    </List>
  );
};

export default Leaderboard;
