import { createContext, useEffect, useReducer } from 'react';

import user from 'services/user';
import stats from 'services/stats';

import { useWallet } from './WalletContext';

const initialState = {
  data: [],
  tokenLeaderboard: [],
  tokenLeaderboardCount: 0,
  nftLeaderboard: [],
  nftLeaderboardCount: 0,
  loading: false,
};

const ACTIONS = {
  SET_DATA: 'SET_DATA',
  SET_LOADING: 'SET_LOADING',
  SET_TOKEN_LEADERBOARD: 'SET_TOKEN_LEADERBOARD',
  SET_TOKEN_COUNT: 'SET_TOKEN_COUNT',
  SET_NFT_LEADERBOARD: 'SET_NFT_LEADERBOARD',
  SET_NFT_COUNT: 'SET_NFT_COUNT',
};

const HodlerAirdropReducer = (state, action) => {
  switch (action.type) {
    case ACTIONS.SET_DATA: {
      return {
        ...state,
        data: action.payload,
      };
    }
    case ACTIONS.SET_LOADING: {
      return {
        ...state,
        loading: action.payload,
      };
    }
    case ACTIONS.SET_TOKEN_LEADERBOARD: {
      return {
        ...state,
        tokenLeaderboard: action.payload,
      };
    }
    case ACTIONS.SET_TOKEN_COUNT: {
      return {
        ...state,
        tokenLeaderboardCount: action.payload,
      };
    }
    case ACTIONS.SET_NFT_LEADERBOARD: {
      return {
        ...state,
        nftLeaderboard: action.payload,
      };
    }
    case ACTIONS.SET_NFT_COUNT: {
      return {
        ...state,
        nftLeaderboardCount: action.payload,
      };
    }
  }

  throw Error('Unknown action: ' + action.type);
};

export const HodlerAirdropContext = createContext(null);

export const HodlerAirdropProvider = ({ children }) => {
  const [state, dispatch] = useReducer(HodlerAirdropReducer, initialState);
  const { wallet, isAuthenticated } = useWallet();

  const fetchHodlerData = async () => {
    setLoading(true);
    try {
      const data = await user.getHodlerBalance();
      setData(data);
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  const fetchTokenHodlerLeaderboard = async () => {
    setLoading(true);
    const result = await stats.getTokenHodlerLeaderboard({ skip: 0, limit: 50 });
    setTokenLeaderboardCount(result.count);
    setTokenLeaderboard(result.data);
    setLoading(false);
  };

  const loadMoreTokenHodler = async () => {
    if (!state.loading) {
      setLoading(true);
      const result = await stats.getTokenHodlerLeaderboard({
        limit: 50,
        skip: state.tokenLeaderboard.length,
      });
      setTokenLeaderboard([...state.tokenLeaderboard, ...result.data]);
      setLoading(false);
    }
  };

  const fetchNFTHodlerLeaderboard = async () => {
    setLoading(true);
    const result = await stats.getNftHodlerLeaderboard({ skip: 0, limit: 50 });
    setNftLeaderboardCount(result.count);
    setNftLeaderboard(result.data);
    setLoading(false);
  };

  const loadMoreNFTHodler = async () => {
    if (!state.loading) {
      setLoading(true);
      const result = await stats.getNftHodlerLeaderboard({
        limit: 50,
        skip: state.nftLeaderboard.length,
      });
      setNftLeaderboard([...state.nftLeaderboard, ...result.data]);
      setLoading(false);
    }
  };

  const setLoading = (loading) => {
    dispatch({ type: ACTIONS.SET_LOADING, payload: loading });
  };

  const setData = (data) => {
    dispatch({ type: ACTIONS.SET_DATA, payload: data });
  };

  const setTokenLeaderboard = (loading) => {
    dispatch({ type: ACTIONS.SET_TOKEN_LEADERBOARD, payload: loading });
  };

  const setTokenLeaderboardCount = (loading) => {
    dispatch({ type: ACTIONS.SET_TOKEN_COUNT, payload: loading });
  };

  const setNftLeaderboard = (loading) => {
    dispatch({ type: ACTIONS.SET_NFT_LEADERBOARD, payload: loading });
  };

  const setNftLeaderboardCount = (loading) => {
    dispatch({ type: ACTIONS.SET_NFT_COUNT, payload: loading });
  };

  useEffect(() => {
    if (wallet && isAuthenticated) {
      fetchHodlerData();
    }
  }, [wallet, isAuthenticated]);

  useEffect(() => {
    fetchTokenHodlerLeaderboard();
    fetchNFTHodlerLeaderboard();
  }, []);

  return (
    <HodlerAirdropContext.Provider
      value={{
        state,
        dispatch,
        loadMoreTokenHodler,
        loadMoreNFTHodler,
      }}
    >
      {children}
    </HodlerAirdropContext.Provider>
  );
};
