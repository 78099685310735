import {
  EarlyDegenCard,
  ApedCard,
  SpreadTheWordCard,
  DiamondBonusCard,
  ReferralBonusCard,
  PowerTweetCard,
} from 'components/PromotionCard';

import {
  ApedIcon,
  EarlyDegenIcon,
  GreenHeartIcon,
  SpreadWordIcon,
  BirdIcon,
  DiamondIcon,
  LooksRareIcon,
  ShipIcon,
  SushiIcon,
  UnicornIcon,
  X2Y2Icon,
  ETHStackerIcon,
} from 'components/ImageComponent';

export const BASE_REWARDS = [
  {
    name: 'NRG Diamond Hands',
    amount: 0,
    id: 'nrgRewardGmi',
    isReady: true,
    icon: DiamondIcon,
    tooltip:
      'Powerful rewards for the NRG Diamond Hands! Purchase and hold NRG through the token generation event to receive big GMI Base reward bonuses!',
  },
  {
    name: 'OpenSea Reward',
    amount: 0,
    id: 'eligibleGmi',
    isReady: true,
    icon: ShipIcon,
    tooltip: 'Based on your OpenSea volume',
  },
  {
    name: 'ETH Stackers Reward',
    amount: 0,
    id: 'ethStackerGmi',
    isReady: true,
    icon: ETHStackerIcon,
    tooltip: 'Based on your ETH balance',
  },
  {
    name: 'LooksRare Reward',
    amount: 0,
    id: 'looksGmi',
    isReady: true,
    icon: LooksRareIcon,
    tooltip: 'Based on your LOOKS balance',
  },
  {
    name: 'X2Y2 Reward',
    amount: 0,
    id: 'x2y2Gmi',
    isReady: true,
    icon: X2Y2Icon,
    tooltip: 'Based on your X2Y2 balance',
  },
  {
    name: 'Sushiswap Reward',
    amount: 0,
    id: 'sushiswapGmi',
    isReady: true,
    icon: SushiIcon,
    tooltip: 'Based on your SUSHI and xSUSHI balance',
  },
  {
    name: 'Uniswap Reward',
    amount: 0,
    id: 'uniswapGmi',
    isReady: true,
    icon: UnicornIcon,
    tooltip: 'Based on your UNI balance',
  },
];

export const PROMOTIONS_CARDS = [
  {
    name: 'Referral Bonus',
    component: ReferralBonusCard,
    icon: GreenHeartIcon,
    bonus: null,
    multiplier: 1,
    completed: false,
    lockedIfNotQualified: true,
  },
  {
    name: 'Early Degen Bonus',
    component: EarlyDegenCard,
    icon: EarlyDegenIcon,
    bonus: null,
    multiplier: 1,
    completed: false,
    lockedIfNotQualified: true,
  },
  {
    name: 'Aped In Bonus',
    component: ApedCard,
    icon: ApedIcon,
    bonus: null,
    multiplier: 1,
    completed: false,
    lockedIfNotQualified: true,
  },
  {
    name: 'NRG Diamond Hands Bonus',
    component: DiamondBonusCard,
    icon: DiamondIcon,
    bonus: null,
    multiplier: 1,
    completed: false,
    lockedIfNotQualified: false,
  },
  {
    name: 'Spread the Word Bonus',
    component: SpreadTheWordCard,
    icon: SpreadWordIcon,
    bonus: null,
    multiplier: 1,
    completed: false,
    lockedIfNotQualified: false,
  },
  {
    name: 'Tweet Bonus',
    component: PowerTweetCard,
    icon: BirdIcon,
    bonus: null,
    multiplier: 1,
    completed: false,
    lockedIfNotQualified: true,
  },
];

export const DIAMOND_LEVELS = {
  maxLevel: 6,
  maxNRG: 250_000,
  upToBonus: 100,
  0: {
    percentage: 20,
    limit: 200,
  },
  1: {
    percentage: 30,
    limit: 1000,
  },
  2: {
    percentage: 40,
    limit: 5000,
  },
  3: {
    percentage: 50,
    limit: 25000,
  },
  4: {
    percentage: 69,
    limit: 100000,
  },
  5: {
    percentage: 100,
    limit: 250000,
  },
};

export const APED_LEVELS = {
  maxLevel: 7,
  0: {
    percentage: 10,
  },
  1: {
    percentage: 12,
  },
  2: {
    percentage: 15,
  },
  3: {
    percentage: 20,
  },
  4: {
    percentage: 30,
  },
  5: {
    percentage: 40,
  },
  6: {
    percentage: 50,
  },
};

export const EARLY_DEGEN_LEVELS = {
  maxLevel: 4,
  0: {
    percentage: 10,
    tier: 4,
  },
  1: {
    percentage: 20,
    tier: 3,
  },
  2: {
    percentage: 25,
    tier: 2,
  },
  3: {
    percentage: 30,
    tier: 1,
  },
};

export const getEarlyDegenLevel = (percentage) => {
  for (const [key, value] of Object.entries(EARLY_DEGEN_LEVELS)) {
    if (value.percentage === percentage) {
      return parseInt(key, 10);
    }
  }
  return EARLY_DEGEN_LEVELS.maxLevel;
};

export const SPREAD_WORD_LEVELS = {
  maxLevel: 5,
  upToBonus: 50,
  0: {
    percentage: 0,
    referrals: 0,
  },
  1: {
    percentage: 10,
    referrals: 1,
  },
  2: {
    percentage: 20,
    referrals: 3,
  },
  3: {
    percentage: 30,
    referrals: 10,
  },
  4: {
    percentage: 40,
    referrals: 20,
  },
  5: {
    percentage: 50,
    referrals: 100,
  },
};

// first record is the power tweet number, second record is the season
export const SEASON_REWARD_POWER_TWEETS = {};

export const POWER_TWEET_BONUS = 20; // %
export const POWER_TWEET_GOLD_REWARD = 1000;
export const POWER_TWEET_NUMBER = 3;
export const POWER_TWEET_EXPIRY = 1744000000000; // UTC Monday, April 7, 2025 4:26:40 AM
