import { createContext, useContext, useEffect, useMemo } from 'react';
import { useWallet } from 'context/WalletContext';
import { useStateIfMounted } from 'use-state-if-mounted';
import apiService from 'services/referral';
import useNotification from 'hooks/useNotification';
import { BASE_REWARDS, PROMOTIONS_CARDS } from 'constants/bonus';

const PromotionContext = createContext();
export const usePromotions = () => useContext(PromotionContext);

/********************  Context Provider ********************/
export const PromotionProvider = ({ children }) => {
  const { wallet, isAuthenticated } = useWallet();
  const showNotification = useNotification();
  const [promotions, setPromotions] = useStateIfMounted(PROMOTIONS_CARDS);
  const [baseRewards, setBaseRewards] = useStateIfMounted(BASE_REWARDS);
  const [airdropBonuses, setAirdropBonuses] = useStateIfMounted(null);
  const [isLoading, setIsLoading] = useStateIfMounted(false);
  const [referralDetails, setReferralDetails] = useStateIfMounted(null);
  const [completedReferralBonus, setCompletedReferralBonus] = useStateIfMounted(false);

  const [spreadTheWordBonus, setSpreadTheWordBonus] = useStateIfMounted({
    level: null,
    limit: null,
    totalReferred: 0,
    modalPopUp: false,
    loading: false,
  });

  const [energiDiamondBonus, setEnergiDiamondBonus] = useStateIfMounted({
    level: null,
    limit: null,
    energiAmount: 0,
    loading: false,
  });

  useEffect(() => {
    if (wallet && isAuthenticated) {
      getAirdropBonuses();
    }
  }, [wallet, isAuthenticated]);

  const availablePromotions = useMemo(
    () => promotions.filter((card) => !card.completed),
    [promotions],
  );

  const completedPromotions = useMemo(
    () => promotions.filter((card) => card.completed),
    [promotions],
  );

  const totalReferrals = useMemo(() => {
    if (referralDetails) {
      const { level1, level2, level3 } = referralDetails;
      return level1.list.length + level2.list.length + level3.list.length;
    }
  }, [referralDetails]);

  const totalEarned = useMemo(() => {
    if (referralDetails) {
      const { level1, level2, level3 } = referralDetails;
      return level1.totalEarn + level2.totalEarn + level3.totalEarn;
    }
  }, [referralDetails]);

  /********************  Airdrop Bonuses  ********************/
  const getAirdropBonuses = async () => {
    setIsLoading(true);
    const data = await apiService.getAirdropBonuses();
    if (data) {
      setAirdropBonuses(data);
      handleReferralLinkBonus(data.bonuses);
      handleSpreadTheWordBonus(data.bonuses.spreadBonus);
      handleNRGDiamondBonus(data.bonuses.nrgBonusAndReward);
      handleEmailBonuses(data);
      handleApedInBonus(data);
      updateBaseRewardsBonusPercentage(data.baseRewards);
    }

    setIsLoading(false);
  };

  /********************  Referral Link Bonus  ********************/
  const getReferrals = async () => {
    setIsLoading(true);
    const data = await apiService.getReferrals();
    setReferralDetails(data);
    if (data?.referredBy) {
      handleCompleteReferralLinkBonus();
    }
    setIsLoading(false);
  };

  const createReferral = async (referralAddress) => {
    if (referralAddress) {
      setIsLoading(true);
      try {
        const response = await apiService.CreateReferral({ referralAddress, phase: 0 });
        if (response.id) {
          handleCompleteReferralLinkBonus();
          setCompletedReferralBonus(true);
          showNotification({
            type: 'success',
            message: 'Referral link confirmed.',
          });
        }
        if (response.success === false) {
          showNotification({
            type: 'error',
            message: "You cannot be added as referree, because you're already a level 0 referrer.",
          });
        }
      } catch (error) {
        showNotification({
          type: 'error',
          message: 'Error applying referral link.',
        });
      }
      setIsLoading(false);
    }
  };

  const handleReferralLinkBonus = (data) => {
    if (data.referredBonus === 1.2) {
      handleCompleteReferralLinkBonus();
    }
  };

  const handleCompleteReferralLinkBonus = () => {
    const referralBonusMultiplier = 1.2;
    completePromotion('Referral Bonus');
    updatePromotionBonus('Referral Bonus', referralBonusMultiplier);
  };

  /********************  Spread the Word Bonus ********************/
  const getSpreadTheWordBonus = async () => {
    setSpreadTheWordBonus({ ...spreadTheWordBonus, loading: true });
    const data = await apiService.getSpreadTheWordBonus();
    handleSpreadTheWordBonus(data);
    setSpreadTheWordBonus({ ...spreadTheWordBonus, loading: false });
  };

  const handleSpreadTheWordBonus = (data) => {
    const { level, limit, multiplier, totalReferred } = data;
    const tiers = [1, 3, 10, 30, 50];
    setSpreadTheWordBonus({
      level,
      limit,
      totalReferred,
      modalPopUp: tiers.includes(totalReferred),
    });
    setTimeout(
      () =>
        setSpreadTheWordBonus((spreadTheWordBonus) => ({
          ...spreadTheWordBonus,
          modalPopUp: false,
        })),
      1000,
    );
    updatePromotionBonus('Spread the Word Bonus', multiplier);
    const amountToComplete = 100;
    if (totalReferred >= amountToComplete) {
      completePromotion('Spread the Word Bonus');
    }
  };

  /********************  NRG Diamond Bonus ********************/
  const getNRGDiamondBonus = async () => {
    setEnergiDiamondBonus({ ...energiDiamondBonus, loading: true });
    const data = await apiService.getNRGDiamondBonus();
    handleNRGDiamondBonus(data);
    setEnergiDiamondBonus({ ...energiDiamondBonus, loading: false });
  };

  const handleNRGDiamondBonus = (data) => {
    const { limit, amount, level, multiplier } = data;
    setEnergiDiamondBonus({
      level,
      limit,
      energiAmount: amount,
    });
    updatePromotionBonus('NRG Diamond Hands Bonus', multiplier);
    const amountToComplete = 250000;
    if (amount >= amountToComplete) {
      completePromotion('NRG Diamond Hands Bonus');
    }
  };

  /**********************  Reserve Email Bonuses **********************/
  const handleEmailBonuses = (data) => {
    if (data.emailStatus) {
      completePromotion('Early Degen Bonus');
      updatePromotionBonus('Early Degen Bonus', data.bonuses.earlyDegenBonus.multiplier);
    }
  };

  /**********************  Reserve Aped In Bonuses **********************/
  const reserveApedInBonus = async ({ discord, twitter, telegram }) => {
    setIsLoading(true);
    const response = await apiService.activateApedInBonus({ discord, twitter, telegram });
    if (response.status) {
      const data = await apiService.getAirdropBonuses();
      handleApedInBonus(data);
    }
    setIsLoading(false);
  };

  const handleApedInBonus = (data) => {
    if (data.apedIn) {
      completePromotion('Aped In Bonus');
      updatePromotionBonus('Aped In Bonus', data.bonuses.apedInBonus.multiplier);
    }
  };

  /******************  Handle Bonus Promotion Completion ******************/
  const completePromotion = (promotionName) => {
    setPromotions((promotions) => {
      return promotions.map((promo) => {
        if (promo.name === promotionName) {
          return { ...promo, completed: true };
        } else {
          return promo;
        }
      });
    });
  };

  /******************  Handle Expire/Remove Promotion ******************/
  const removePromotion = (promotionName) => {
    setPromotions((promotions) => {
      return promotions.filter((promo) => promo.name !== promotionName);
    });
  };

  /********************  Update Bonus Promotion Value ********************/
  const updatePromotionBonus = (promotionName, multiplier) => {
    setPromotions((promotions) => {
      return promotions.map((promo) => {
        if (promo.name === promotionName) {
          return { ...promo, multiplier, bonus: Math.round(multiplier * 100 - 100) };
        } else {
          return promo;
        }
      });
    });
  };

  /*****************  Update Base Rewards Bonus Amount *****************/
  const updateBaseRewards = (rewardName, amount) => {
    setBaseRewards((baseRewards) => {
      return baseRewards.map((reward) => {
        if (reward.id === rewardName) {
          return { ...reward, amount };
        } else {
          return reward;
        }
      });
    });
  };

  const updateBaseRewardsBonusPercentage = (data) => {
    return baseRewards.map((reward) => {
      if (reward.id in data) {
        updateBaseRewards(reward.id, data[reward.id]);
      }
    });
  };

  return (
    <PromotionContext.Provider
      value={{
        promotions,
        baseRewards,
        airdropBonuses,
        availablePromotions,
        completedPromotions,
        isLoading,
        referralDetails,
        totalReferrals,
        completedReferralBonus,
        createReferral,
        getReferrals,
        spreadTheWordBonus,
        getSpreadTheWordBonus,
        energiDiamondBonus,
        getNRGDiamondBonus,
        getAirdropBonuses,
        removePromotion,
        handleEmailBonuses,
        reserveApedInBonus,
        totalEarned,
      }}
    >
      {children}
    </PromotionContext.Provider>
  );
};
