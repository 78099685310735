import { useStateIfMounted } from 'use-state-if-mounted';
import { styled } from '@mui/material';
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';

import { formatAddress } from 'utils/address';

import Blockie from 'components/Blockie';
import LinkedWalletsModal from './LinkedWalletsModal';

/********************  Styled Components  ********************/
const UserButton = styled((props) => <Button variant="contained" disableRipple {...props} />)(
  ({ theme, open }) => ({
    backgroundColor: theme.palette.background.default,
    border: `1px solid ${!open ? theme.palette.border.decorative : theme.palette.button.active}`,
    borderRadius: 8,
    lineHeight: '24px',
    letterSpacing: '0.15px',
    fontSize: 16,
    fontWeight: 600,
    color: theme.palette.text.secondary,
    boxShadow: 'none',
    height: 48,

    '&:hover': {
      border: `1px solid ${theme.palette.border.medium}`,
      backgroundColor: theme.palette.background.default,
      boxShadow: 'none',
    },

    '&:focus': {
      border: `1px solid ${theme.palette.button.active}`,
      backgroundColor: theme.palette.border.primary,
    },

    '&:active': {
      border: `1px solid ${theme.palette.button.active}`,
      backgroundColor: theme.palette.border.primary,
    },

    '&:disabled': {
      opacity: 0.24,
      border: `1px solid ${theme.palette.border.medium}`,
      color: theme.palette.text.main,
      backgroundColor: theme.palette.background.default,
    },
  }),
);

const StyledMenu = styled((props) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'right',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'right',
    }}
    {...props}
  />
))(({ theme }) => ({
  position: 'absolute',
  '& .MuiPaper-root': {
    borderRadius: 6,
    marginTop: theme.spacing(1),
    minWidth: 240,
    color: theme.palette.button.secondary,
    boxShadow:
      'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
    '& .MuiMenu-list': {
      padding: 0,
    },
  },
}));

const StyledMenuItem = styled(MenuItem)(({ theme }) => ({
  paddingTop: theme.spacing(2),
  paddingBottom: theme.spacing(2),
  minHeight: 'auto',
}));

/********************  Main Component  ********************/
const AddressButton = ({ address, twitterName }) => {
  const [showLinkedWalletsModal, setShowLinkedWalletsModal] = useStateIfMounted(false);
  const [anchorEl, setAnchorEl] = useStateIfMounted(null);
  const open = Boolean(anchorEl);

  const handleOpen = (event) => {
    if (anchorEl !== event.currentTarget) {
      setAnchorEl(event.currentTarget);
    }
  };

  return (
    <div>
      <UserButton
        id="menu-button"
        aria-label="Wallets"
        aria-controls={open ? 'menu-button' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={handleOpen}
      >
        <Blockie size={4} customAddress={address} marginRight={1} />
        {twitterName ?? formatAddress(address, 5, 3)}
      </UserButton>
      <StyledMenu
        id="wallet-menu"
        MenuListProps={{ 'aria-labelledby': 'menu-button' }}
        anchorEl={anchorEl}
        open={open}
        onClose={() => setAnchorEl(null)}
      >
        <StyledMenuItem
          onClick={() => {
            setShowLinkedWalletsModal(true);
            setAnchorEl(null);
          }}
        >
          Linked Wallets
        </StyledMenuItem>
      </StyledMenu>
      {showLinkedWalletsModal && (
        <LinkedWalletsModal onClose={() => setShowLinkedWalletsModal(false)} />
      )}
    </div>
  );
};

export default AddressButton;
