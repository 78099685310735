import { Box, styled } from '@mui/material';
import Blockies from 'react-blockies';

const BlockieContainer = styled(Box)(({ size, rest }) => ({
  borderRadius: '50%',
  width: size * 8,
  height: size * 8,
  overflow: 'hidden',
  ...rest,
}));

const Blockie = ({ size = 4, customAddress, ...rest }) => {
  return (
    <BlockieContainer size={size} {...rest}>
      <Blockies scale={size} seed={customAddress} />
    </BlockieContainer>
  );
};

export default Blockie;
