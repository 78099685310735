import { ROUTES } from 'pages/routes';
import {
  XLogo,
  InstagramIcon,
  DiscordIcon,
  RedditIcon,
  YouTubeIcon,
  FacebookIcon,
  LinkedInIcon,
  WhatsAppIcon,
  NRGTokenIcon,
  ETHTokenIcon,
  USDTTokenIcon,
  USDCTokenIcon,
  DAITokenIcon,
  PEPETokenIcon,
  XAirdropIcon,
  LiquidityAirdropIcon,
  CreatorAirdropIcon,
  TraderAirdropIcon,
  TokenAirdropIcon,
  NftAirdropIcon,
  LeaderboardsIcon,
  BoostersIcon,
  ZealyAirdropIcon,
  NftVolumeAirdropIcon,
  NftBridingAirdropIcon,
  NftPortfolioAirdropIcon,
  NftListingAirdropIcon,
  PowerTweetAirdropIcon,
  //MemeCreatorIcon, hide for now
  ReferralContestIcon,
} from 'components/ImageComponent';
import {
  CreatorDataRow,
  LiquidityDataRow,
  NftHodlerDataRow,
  TokenHodlerDataRow,
  TraderDataRow,
  XDataRow,
  NftVolumeDataRow,
  ZealyDataRow,
  NftPortfolioDataRow,
  PowerTweetDataRow,
  ListingDataRow,
  ReferralContestDataRow,
} from 'pages/Leaderboard/components/Table';

// Login
export const SIGNATURE_EXPIRY_TIME = 7 * 24 * 60 * 60 * 1000; // 7 days
export const AUTH_MESSAGE_TO_SIGN = (address, nonce) => {
  return `Welcome to GMI!\n\nClick to sign in and accept the GMI Terms of Service\n\nThis request will not trigger a blockchain transaction or cost any gas fees.\n\nYour authentication status will reset after 7 days.\n\nWallet address: ${address}\n\nNonce: ${nonce}`;
};

// Social Media
export const SOCIAL_MEDIAS = [
  {
    name: 'Twitter',
    link: 'https://x.com/energi',
    icon: <XLogo />,
  },
  {
    name: 'Instagram',
    link: 'https://www.instagram.com/energicrypto',
    icon: <InstagramIcon />,
  },
  {
    name: 'Discord',
    link: 'https://discord.com/invite/sCtgNC3/',
    icon: <DiscordIcon />,
  },
  {
    name: 'Reddit',
    link: 'https://www.reddit.com/r/energicryptocurrency/',
    icon: <RedditIcon />,
  },
  {
    name: 'Youtube',
    link: 'https://www.youtube.com/c/Energicrypto',
    icon: <YouTubeIcon />,
  },
];
export const SOCIAL_LINKS = {
  DiscordEnergi: 'https://discord.com/invite/sCtgNC3',
  DiscordDD: 'https://discord.gg/6nU4zyNxyH',
  TwitterDD: 'https://x.com/DragonDegensNFT',
  TwitterGMI: 'https://x.com/gonnamakeitnfts',
  TwitterEnergi: 'https://x.com/energi',
  TwitterTommy: 'https://x.com/TommyWorldPower',
  TwitterRyan: 'https://x.com/ryanlucchese',
  Telegram: 'https://t.me/GonnaMakeitNFTs',
};
export const SOCIAL_MEDIA_SHARE_INFO = [
  {
    name: 'X(Twitter)',
    background: '#FFFFFF',
    hovered: '#DDDDDD',
    active: '#CCCCCC',
    icon: <XLogo sx={{ width: 32, height: 32 }} />,
  },
  {
    name: 'Facebook',
    background: '#4267B2',
    hovered: '#567CCA',
    active: '#4366AC',
    icon: <FacebookIcon sx={{ width: 32, height: 32 }} />,
  },
  {
    name: 'LinkedIn',
    background: '#217BB0',
    hovered: '#358EC2',
    active: '#217BB0',
    icon: <LinkedInIcon sx={{ width: 32, height: 32 }} />,
  },
  {
    name: 'Reddit',
    background: '#FF5700',
    hovered: '#FF6617',
    active: '#F65806',
    icon: <RedditIcon sx={{ width: 32, height: 32 }} />,
  },
  {
    name: 'WhatsApp',
    background: '#25D366',
    hovered: '#3EE87D',
    active: '#1DCA5D',
    icon: <WhatsAppIcon sx={{ width: 32, height: 32 }} />,
  },
];

// Email
export const EMAIL_VERIFICATION_DELAY = 60000; // 1 minute
export const EMAIL_LOCAL_STORAGE_ID = 'email';

// Links
export const GMI_LINKS = {
  ReferralProgram: 'https://docs.gonnamakeit.com/docs/about/gmi-referral',
  PrivacyPolicy: 'https://gonnamakeit.com/privacy-policy/',
  GetNRG: 'https://energi.world/get-nrg/',
  DragonDegen: 'https://energistudios.com/dragon-degens-nfts/',
  AirdropDocs: 'https://docs.gonnamakeit.com/docs/airdrop/gmi-airdrop-current',
  Website: 'https://gonnamakeit.com/',
  Webapp: 'https://app.gonnamakeit.com/',
};

export const LEGACY_BRIDGE_URL = 'https://bridge.energi.network';
export const MULTI_CHAIN_BRIDGE_URL = 'https://mbridge.energi.network';
export const METAMASK_URL = 'https://metamask.io/download.html';
export const METAMASK_GUIDE_URL =
  'https://wiki.energi.world/docs/quickstart/getting-started-with-metamask';
export const ZEALY_URL = 'https://zealy.io/cw/energicrew/questboard';
export const REFERRAL_PROGRAM_URL = 'https://ref.gonnamakeit.com';
export const NFT_BRIDGE_URL = 'https://bridge.gonnamakeit.com/';

// UI
export const AIRDROP_MENUS = [
  {
    title: 'LEADERBOARDS',
    icon: <LeaderboardsIcon height="100%" />,
    iconMobile: <LeaderboardsIcon height="100%" />,
    to: ROUTES.Leaderboard,
  },
  {
    title: 'BOOSTERS',
    icon: <BoostersIcon height="130%" />,
    iconMobile: <BoostersIcon height="130%" />,
    to: ROUTES.Boosters,
  },
  {
    title: 'REFERRAL CONTEST AIRDROP',
    icon: <ReferralContestIcon width="60%" height="auto" />,
    iconMobile: <ReferralContestIcon width="80%" height="auto" />,
    to: ROUTES.ReferralProgramContest,
  },
  {
    title: 'POWER TWEET AIRDROP',
    icon: <PowerTweetAirdropIcon height="100%" />,
    iconMobile: <PowerTweetAirdropIcon height="100%" />,
    to: ROUTES.PowerTweetAirdrop,
  },
  {
    title: 'TRADER AIRDROP',
    icon: <TraderAirdropIcon height="100%" />,
    iconMobile: <TraderAirdropIcon height="100%" />,
    to: ROUTES.TraderAirdrop,
  },
  {
    title: 'BIDDING & LISTING AIRDROP',
    icon: <NftListingAirdropIcon height="100%" />,
    iconMobile: <NftListingAirdropIcon height="100%" />,
    to: ROUTES.NftListingAirdrop,
  },
  {
    title: 'MARKETPLACE VOLUME AIRDROP',
    icon: <NftVolumeAirdropIcon height="50px" />,
    iconMobile: <NftVolumeAirdropIcon height="50px" />,
    to: ROUTES.NftVolumeAirdrop,
  },
  {
    title: 'TOKEN HODLER AIRDROP',
    icon: <TokenAirdropIcon height="100%" />,
    iconMobile: <TokenAirdropIcon height="100%" />,
    to: ROUTES.TokenHodler,
  },
  {
    title: 'NFT HODLER AIRDROP',
    icon: <NftAirdropIcon height="100%" />,
    iconMobile: <NftAirdropIcon height="100%" />,
    to: ROUTES.NftHodler,
  },
  {
    title: 'LIQUIDITY AIRDROP',
    icon: <LiquidityAirdropIcon height="100%" />,
    iconMobile: <LiquidityAirdropIcon height="100%" />,
    to: ROUTES.LiquidityAirdrop,
  },
  {
    title: 'X AIRDROP',
    icon: <XAirdropIcon height="100%" />,
    iconMobile: <XAirdropIcon height="100%" />,
    to: ROUTES.XDashboard,
  },
  {
    title: 'PORTFOLIO AIRDROP',
    icon: <NftPortfolioAirdropIcon height="100%" />,
    iconMobile: <NftPortfolioAirdropIcon height="100%" />,
    to: ROUTES.NftPortfolioAirdrop,
  },
  {
    title: 'CREATOR AIRDROP',
    icon: <CreatorAirdropIcon height="100%" />,
    iconMobile: <CreatorAirdropIcon height="100%" />,
    to: ROUTES.CreatorAirdrop,
  },
  {
    title: 'ZEALY AIRDROP',
    icon: <ZealyAirdropIcon height="100%" />,
    iconMobile: <ZealyAirdropIcon height="100%" />,
    to: ROUTES.ZealyAirdrop,
  },
  {
    title: 'NFT BRIDGING AIRDROP',
    icon: <NftBridingAirdropIcon height="100%" />,
    iconMobile: <NftBridingAirdropIcon height="100%" />,
    to: ROUTES.NftBridgeAirdrop,
  },
  /*{
    title: 'MEME CREATOR AIRDROP',
    icon: <MemeCreatorIcon height="100%" />,
    to: ROUTES.MemeCreatorAirdrop,
  },*/
];
export const TOKEN_ICONS = {
  NRG: <NRGTokenIcon width={22} height={22} />,
  ETH: <ETHTokenIcon width={22} height={22} />,
  USDT: <USDTTokenIcon width={22} height={22} />,
  USDC: <USDCTokenIcon width={22} height={22} />,
  DAI: <DAITokenIcon width={22} height={22} />,
  PEPE: <PEPETokenIcon width={22} height={22} />,
};

// Airdrop
export const SEASONS = {
  2: {
    start: 1720080000000, // UTC Thursday, July 4, 2024 8:00:00 AM
    end: 1725436800000, // UTC Wednesday, September 4, 2024 8:00:00 AM
  },
  3: {
    start: 1725580800000, // UTC Friday, September 6, 2024 12:00:00 AM
    end: 1727740800000, // UTC Tuesday, October 1, 2024 12:00:00 AM
  },
  4: {
    start: 1728316800000, // UTC Monday, October 7, 2024 4:00:00 PM
    end: 1730908800000, // UTC Thursday, November 6, 2024 4:00:00 PM
  },
  5: {
    start: 1731427200000, // UTC Tuesday, November 12, 2024 4:00:00 PM
    end: 1735315200000, // UTC Friday, December 27, 2024 4:00:00 PM
  },
  6: {
    start: 1735743600000, // UTC Wednesday, January 1, 2025 3:00:00 PM
    end: 1738368000000, // UTC Saturday, February 1, 2025 12:00:00 AM
  },
  7: {
    start: 1738605600000, // UTC Monday, February 3, 2025 6:00:00 PM
    end: 1741200000000, // UTC Wednesday, March 5, 2025 6:40:00 PM
  },
  8: {
    start: 1741410000000, // UTC Saturday, March 8, 2025 5:00:00 AM
    end: 1744100000000, // UTC Tuesday, April 8, 2025 8:13:20 AM
  },
};

export const AIRDROP_SEASON = 8;
export const AIRDROP_GMI_REWARDS = {
  referralContest: 3,
  powerTweet: 1,
  trader: 2,
  listing: 2,
  marketplaceVolume: 1,
  tokenHodler: 1,
  nftHodler: 1,
  liquidity: 1,
  x: 1,
  portfolio: 1,
  creator: 1,
  zealy: 1,
  bridging: 1,
};
export const TOP_10_GMI_REWARDS = 420_000;
export const AIRDROP_START_DATE = new Date(SEASONS[AIRDROP_SEASON].start);
export const AIRDROP_FINISH_DATE = new Date(SEASONS[AIRDROP_SEASON].end);

export const CURRENT_REWARD_SEASON = 7;

export const RP_X_SHARE_MESSAGE = `Have you joined the GMI Airdrop yet?

🚀 Earn big rewards with 0 investment, juicy bonuses, and a 3-level referral system!

Just by referring others your rewards will stack fast! Use my ref code for 30% bonus👇`;

export const POWER_TWEET_SHARE_MESSAGE = `Just joined the @GonnaMakeItNFTs TRIPLE XP NFT Trading Contest running this week!🔥

 ⚡️ 3X Topaz on all trades!
 ⚡️ 3X Zealy XP on all trades!

Use my referral link for an additional 30% Boost on your rewards👇`;

export const POWER_TWEET_REWARD_MESSAGE = (totalGMI) =>
  totalGMI === 0
    ? `💸 16M GMI distributed to Season ${CURRENT_REWARD_SEASON} participants! with @GonnaMakeItNFTs! Earn big rewards with 0 investment, quick bonuses, and a 3-level referral system!

Even if you miss base points, referrals can stack rewards fast. Use my ref code for 30% bonus👇`
    : `🚀 Just scored ${totalGMI.toLocaleString()} GMI in Season ${CURRENT_REWARD_SEASON}! 💎 With @GonnaMakeItNFTs, you can gain big rewards with 0 investment, quick bonuses, and a 3-level referral system!

Just by inviting others, referral rewards can stack rewards fast. Use my ref code for 30% bonus👇
`;

export const AIRDROP_DOCS_LINK = 'https://docs.gonnamakeit.com/docs/airdrop/gmi-airdrop-current';

export const BRIDGED_TOKENS = ['ETH', 'USDT', 'USDC', 'DAI', 'PEPE'];
export const LIQUIDITY_PAIRS = {
  testnet: {
    Energi: [
      {
        address: '0x47e9f80f425884b384a70da81fe6db8685996cca',
        token0: 'NRG',
        token1: 'ETH',
        token1Address: '0x01a5534f82Ab54e1F37Ee585F3049Cf8B80f79A4',
      },
      {
        address: '0x3a0eeaa0033f12504d03364dae19af67b8098a1c',
        token0: 'NRG',
        token1: 'USDT',
        token1Address: '0xc52C33571EfFfeb77Fc8b45B5c9c142723bF92d8',
      },
      {
        address: '0xb386c099059d2e4f96ca88bab93d07da2f324dd8',
        token0: 'NRG',
        token1: 'USDC',
        token1Address: '0x271268cebeB310D8B19F088aDEBF3f247910562c',
      },
      {
        address: '0x9e752bb585c870e4161be5f5e09dc1ba2a122ee9',
        token0: 'NRG',
        token1: 'DAI',
        token1Address: '0x3e798553502042088783B83e4B62e22152E8717e',
      },
      {
        address: '0x0000000000000000000000000000000000000000',
        token0: 'NRG',
        token1: 'PEPE',
        token1Address: '0x0000000000000000000000000000000000000000',
      },
      {
        address: '0x0000000000000000000000000000000000000000',
        token0: 'NRG',
        token1: 'PEPE',
        token1Address: '0x0000000000000000000000000000000000000000',
      },
    ],
    Ethereum: [
      {
        token0: 'ETH',
        token1: 'NRG',
        address: '0x0000000000000000000000000000000000000000',
        token1Address: '0x0000000000000000000000000000000000000000',
      },
    ],
  },
  mainnet: {
    Energi: [
      {
        address: '0xb8477E82CbFB664B465979A7819b7F640880277c',
        token0: 'NRG',
        token1: 'ETH',
        token1Address: '0x78B050d981d7f6E019Bf6E361D0d1167de6B19dA',
      },
      {
        address: '0x3F0a1D7C138C18CdB4B9A51Ec932d217e7b80942',
        token0: 'NRG',
        token1: 'USDT',
        token1Address: '0x470075Cf46e6132aaD78C40a1BE53a494b05E831',
      },
      {
        address: '0xf3963f014b8525D7B79D2491aD40B83fB37CCC63',
        token0: 'NRG',
        token1: 'USDC',
        token1Address: '0xFFD7510ca0a3279c7a5F50018A26c21d5bc1DBcF',
      },
      {
        address: '0x5Cc8B3D89AE2cA30A08b6697E33606FC3437d392',
        token0: 'NRG',
        token1: 'DAI',
        token1Address: '0x0ee5893f434017d8881750101Ea2F7c49c0eb503',
      },
      {
        address: '0xacA8dC617f4828693FdC22240b1530700caFE4c3',
        token0: 'NRG',
        token1: 'PEPE',
        token1Address: '0x52B16632A9ed3977D7D701108bD548Ce693b610c',
      },
    ],
    Ethereum: [
      {
        token0: 'NRG',
        token1: 'ETH',
        address: '0xd2624380a006220574c9e75083490b042e71ee42',
        token1Address: '0x1416946162B1C2c871A73B07E932D2fB6C932069',
      },
    ],
  },
}[process.env.REACT_APP_ENVIRONMENT ?? 'mainnet'];
export const LIQUIDITY_POOL_LINKS = {
  testnet: {
    Energi: 'https://app.test.energiswap.exchange/#/add/',
    Ethereum: 'https://app.uniswap.org/add/',
  },
  mainnet: {
    Energi: 'https://app.energiswap.exchange/#/add/',
    Ethereum: 'https://app.uniswap.org/add/',
  },
}[process.env.REACT_APP_ENVIRONMENT ?? 'mainnet'];
export const CHAIN = {
  mainnet: {
    Energi: 39797,
    Ethereum: 1,
  },
  testnet: {
    Energi: 49797,
    Ethereum: 1,
  },
}[process.env.REACT_APP_ENVIRONMENT ?? 'mainnet'];
export const LEADERBOARDS = [
  {
    key: 'referralContest',
    value: 'Referral Contest Airdrop Leaderboard',
    menuTitle: 'Referral Contest Airdrop',
    row: ReferralContestDataRow,
    headers: [
      { id: 'rank', label: 'Rank' },
      { id: 'account', label: 'Account', align: 'left' },
      { id: 'platinum', label: 'Score (Platinum)' },
    ],
  },
  {
    key: 'powerTweet',
    value: 'Power Tweet Airdrop Leaderboard',
    menuTitle: 'Power Tweet Airdrop',
    row: PowerTweetDataRow,
    headers: [
      { id: 'rank', label: 'Rank' },
      { id: 'address', label: 'Wallet Address', align: 'left' },
      { id: 'score', label: 'Score (Gold)' },
    ],
  },
  {
    key: 'trader',
    value: 'Trader Airdrop Leaderboard',
    menuTitle: 'Trader Airdrop',
    row: TraderDataRow,
    headers: [
      { id: 'rank', label: 'Rank' },
      { id: 'wallet', label: 'Wallet Address', align: 'left' },
      { id: 'volume', label: 'Total Volume', hideOnMobile: true, align: 'right' },
      { id: 'score', label: 'Score (Topaz)', align: 'right' },
    ],
  },
  {
    key: 'listing',
    value: 'Bidding & Listing Airdrop Leaderboard',
    menuTitle: 'Bidding & Listing Airdrop',
    row: ListingDataRow,
    headers: [
      { id: 'rank', label: 'Rank' },
      { id: 'wallet', label: 'Wallet Address', align: 'left' },
      { id: 'score', label: 'Score (Mana)' },
    ],
  },
  {
    key: 'nftVolume',
    value: 'Marketplace Vol Airdrop Leaderboard',
    menuTitle: 'Marketplace Vol Airdrop',
    row: NftVolumeDataRow,
    headers: [
      { id: 'rank', label: 'Rank' },
      { id: 'wallet', label: 'Wallet Address', align: 'left' },
      { id: 'score', label: 'Score (Diamonds)' },
    ],
  },
  {
    key: 'token',
    value: 'Token Hodler Airdrop Leaderboard',
    menuTitle: 'Token Hodler Airdrop',
    row: TokenHodlerDataRow,
    headers: [
      { id: 'rank', label: 'Rank' },
      { id: 'wallet', label: 'Wallet Address', align: 'left' },
      { id: 'score', label: 'Score (Aether)' },
    ],
  },
  {
    key: 'nft',
    value: 'NFT Hodler Airdrop Leaderboard',
    menuTitle: 'NFT Hodler Airdrop',
    row: NftHodlerDataRow,
    headers: [
      { id: 'rank', label: 'Rank' },
      { id: 'wallet', label: 'Wallet Address', align: 'left' },
      { id: 'score', label: 'Score (Rubies)' },
    ],
  },
  {
    key: 'liquidity',
    value: 'Liquidity Airdrop Leaderboard',
    menuTitle: 'Liquidity',
    row: LiquidityDataRow,
    headers: [
      { id: 'rank', label: 'Rank' },
      { id: 'wallet', label: 'Wallet Address', align: 'left' },
      { id: 'liquidity', label: 'Total Liquidity', hideOnMobile: true },
      { id: 'score', label: 'Score (Sapphires)' },
    ],
  },
  {
    key: 'x',
    value: 'X Airdrop Leaderboard',
    menuTitle: 'X',
    row: XDataRow,
    headers: [
      { id: 'rank', label: 'Rank' },
      { id: 'twitterHandle', label: 'Twitter Handle', align: 'left' },
      { id: 'tweets', label: 'Tweets', hideOnMobile: true },
      { id: 'views', label: 'Views', hideOnMobile: true },
      { id: 'score', label: 'Score (Amethysts)' },
    ],
  },
  {
    key: 'portfolio',
    value: 'Portfolio Airdrop Leaderboard',
    menuTitle: 'Portfolio Airdrop',
    row: NftPortfolioDataRow,
    headers: [
      { id: 'rank', label: 'Rank' },
      { id: 'wallet', label: 'Wallet Address', align: 'left' },
      { id: 'score', label: 'Score (Emeralds)' },
    ],
  },
  {
    key: 'creator',
    value: 'Creator Airdrop Leaderboard',
    menuTitle: 'Creator Airdrop',
    row: CreatorDataRow,
    headers: [
      { id: 'rank', label: 'Rank' },
      { id: 'collection', label: 'Collection Name', align: 'left' },
      { id: 'volume', label: 'Total Volume', hideOnMobile: true, align: 'right' },
      { id: 'score', label: 'Score (Quartz)', align: 'right' },
    ],
  },
  {
    key: 'zealy',
    value: 'Zealy Airdrop Leaderboard',
    menuTitle: 'Zealy Airdrop',
    row: ZealyDataRow,
    headers: [
      { id: 'rank', label: 'Rank' },
      { id: 'username', label: 'Username', align: 'left' },
      { id: 'score', label: 'Score (Pearls)' },
    ],
  },
];
export const NFT_HODLER = [
  {
    name: 'Bored Ape Yacht Club',
    title: 'BAYC Hodler Airdrop',
    pointsTitle: [
      'BAYC Hodler Base Rubies:',
      'BAYC Hodler Booster Rubies:',
      'BAYC Hodler Referral Rubies:',
      'BAYC Hodler Total Rubies:',
    ],
    values: [null, null, null, null],
  },
  {
    name: 'Mutant Ape Yacht Club',
    title: 'MAYC Hodler Airdrop',
    pointsTitle: [
      'MAYC Hodler Base Rubies:',
      'MAYC Hodler Booster Rubies:',
      'MAYC Hodler Referral Rubies:',
      'MAYC Hodler Total Rubies:',
    ],
    values: [null, null, null, null],
  },
  {
    name: 'Quirkies',
    title: 'Quirkies Hodler Airdrop',
    pointsTitle: [
      'Quirkies Hodler Base Rubies:',
      'Quirkies Hodler Booster Rubies:',
      'Quirkies Hodler Referral Rubies:',
      'Quirkies Hodler Total Rubies:',
    ],
    values: [null, null, null, null],
  },
  {
    name: 'Crypto Chicks',
    title: 'Crypto.Chicks Hodler Airdrop',
    pointsTitle: [
      'Crypto.Chicks Hodler Base Rubies:',
      'Crypto.Chicks Hodler Booster Rubies:',
      'Crypto.Chicks Hodler Referral Rubies:',
      'Crypto.Chicks Hodler Total Rubies:',
    ],
    values: [null, null, null, null],
  },
  {
    name: 'Wumbo Pass',
    title: 'Wumbologists Hodler Airdrop',
    pointsTitle: [
      'Wumbologists Hodler Base Rubies:',
      'Wumbologists Hodler Booster Rubies:',
      'Wumbologists Hodler Referral Rubies:',
      'Wumbologists Hodler Total Rubies:',
    ],
    values: [null, null, null, null],
  },
  {
    name: 'Nonconformist Ducks',
    title: 'NoncoDucks Hodler Airdrop',
    pointsTitle: [
      'NoncoDucks Hodler Base Rubies:',
      'NoncoDucks Hodler Booster Rubies:',
      'NoncoDucks Hodler Referral Rubies:',
      'NoncoDucks Hodler Total Rubies:',
    ],
    values: [null, null, null, null],
  },

  {
    name: 'SHIBU Society',
    title: 'SHIBU Society Hodler Airdrop',
    pointsTitle: [
      'SHIBU Society Hodler Base Rubies:',
      'SHIBU Society Hodler Booster Rubies:',
      'SHIBU Society Hodler Referral Rubies:',
      'SHIBU Society Hodler Total Rubies:',
    ],
    values: [null, null, null, null],
  },
];
export const TOKEN_HODLER = [
  {
    name: 'Ethereum',
    title: 'ETH Hodler Airdrop',
    pointsTitle: [
      'ETH Hodler Base Aether:',
      'ETH Hodler Booster Aether:',
      'ETH Hodler Referral Aether:',
      'ETH Hodler Total Aether:',
    ],
    values: [null, null, null, null],
  },
  {
    name: 'Uniswap',
    title: 'Uniswap Hodler Airdrop',
    pointsTitle: [
      'Uniswap Hodler Base Aether:',
      'Uniswap Hodler Booster Aether:',
      'Uniswap Hodler Referral Aether:',
      'Uniswap Hodler Total Aether:',
    ],
    values: [null, null, null, null],
  },
  {
    name: 'USDC',
    title: 'USDC Hodler Airdrop',
    pointsTitle: [
      'USDC Hodler Base Aether:',
      'USDC Hodler Booster Aether:',
      'USDC Hodler Referral Aether:',
      'USDC Hodler Total Aether:',
    ],
    values: [null, null, null, null],
  },
  {
    name: 'Pepe',
    title: 'PEPE Hodler Airdrop',
    pointsTitle: [
      'PEPE Hodler Base Aether:',
      'PEPE Hodler Booster Aether:',
      'PEPE Hodler Referral Aether:',
      'PEPE Hodler Total Aether:',
    ],
    values: [null, null, null, null],
  },
  {
    name: 'Shiba Inu',
    title: 'SHIB Hodler Airdrop',
    pointsTitle: [
      'SHIB Hodler Base Aether:',
      'SHIB Hodler Booster Aether:',
      'SHIB Hodler Referral Aether:',
      'SHIB Hodler Total Aether:',
    ],
    values: [null, null, null, null],
  },
  {
    name: 'Mog Coin',
    title: 'MOG Hodler Airdrop',
    pointsTitle: [
      'MOG Hodler Base Aether:',
      'MOG Hodler Booster Aether:',
      'MOG Hodler Referral Aether:',
      'MOG Hodler Total Aether:',
    ],
    values: [null, null, null, null],
  },
  {
    name: 'Chain Link',
    title: 'ChainLink Hodler Airdrop',
    pointsTitle: [
      'ChainLink Hodler Base Aether:',
      'ChainLink Hodler Booster Aether:',
      'ChainLink Hodler Referral Aether:',
      'ChainLink Hodler Total Aether:',
    ],
    values: [null, null, null, null],
  },
  {
    name: 'Balancer',
    title: 'Balancer Hodler Airdrop',
    pointsTitle: [
      'Balancer Hodler Base Aether:',
      'Balancer Hodler Booster Aether:',
      'Balancer Hodler Referral Aether:',
      'Balancer Hodler Total Aether:',
    ],
    values: [null, null, null, null],
  },
  {
    name: 'Decentraland',
    title: 'Decentraland Hodler Airdrop',
    pointsTitle: [
      'Decentraland Hodler Base Aether:',
      'Decentraland Hodler Booster Aether:',
      'Decentraland Hodler Referral Aether:',
      'Decentraland Hodler Total Aether:',
    ],
    values: [null, null, null, null],
  },
  {
    name: 'Aave Token',
    title: 'AAVE Hodler Airdrop',
    pointsTitle: [
      'AAVE Hodler Base Aether:',
      'AAVE Hodler Booster Aether:',
      'AAVE Hodler Referral Aether:',
      'AAVE Hodler Total Aether:',
    ],
    values: [null, null, null, null],
  },
  {
    name: 'Compound',
    title: 'Compound Hodler Airdrop',
    pointsTitle: [
      'Compound Hodler Base Aether:',
      'Compound Hodler Booster Aether:',
      'Compound Hodler Referral Aether:',
      'Compound Hodler Total Aether:',
    ],
    values: [null, null, null, null],
  },
  {
    name: 'Lido DAO Token',
    title: 'Lido Hodler Airdrop',
    pointsTitle: [
      'Lido Hodler Base Aether:',
      'Lido Hodler Booster Aether:',
      'Lido Hodler Referral Aether:',
      'Lido Hodler Total Aether:',
    ],
    values: [null, null, null, null],
  },
  {
    name: 'Curve DAO Token',
    title: 'Curve Hodler Airdrop',
    pointsTitle: [
      'Curve Hodler Base Aether:',
      'Curve Hodler Booster Aether:',
      'Curve Hodler Referral Aether:',
      'Curve Hodler Total Aether:',
    ],
    values: [null, null, null, null],
  },
  {
    name: 'Blast',
    title: 'BLAST Hodler Airdrop',
    pointsTitle: [
      'BLAST Hodler Base Aether:',
      'BLAST Hodler Booster Aether:',
      'BLAST Hodler Referral Aether:',
      'BLAST Hodler Total Aether:',
    ],
    values: [null, null, null, null],
  },
  {
    name: 'Blur',
    title: 'BLUR Hodler Airdrop',
    pointsTitle: [
      'BLUR Hodler Base Aether:',
      'BLUR Hodler Booster Aether:',
      'BLUR Hodler Referral Aether:',
      'BLUR Hodler Total Aether:',
    ],
    values: [null, null, null, null],
  },
  {
    name: 'Trump',
    title: 'Trump Hodler Airdrop',
    pointsTitle: [
      'Trump Hodler Base Aether:',
      'Trump Hodler Booster Aether:',
      'Trump Hodler Referral Aether:',
      'Trump Hodler Total Aether:',
    ],
    values: [null, null, null, null],
  },
  {
    name: 'Turbo',
    title: 'Turbo Hodler Airdrop',
    pointsTitle: [
      'Turbo Hodler Base Aether:',
      'Turbo Hodler Booster Aether:',
      'Turbo Hodler Referral Aether:',
      'Turbo Hodler Total Aether:',
    ],
    values: [null, null, null, null],
  },
  {
    name: 'Floki',
    title: 'Floki Hodler Airdrop',
    pointsTitle: [
      'Floki Hodler Base Aether:',
      'Floki Hodler Booster Aether:',
      'Floki Hodler Referral Aether:',
      'Floki Hodler Total Aether:',
    ],
    values: [null, null, null, null],
  },
  {
    name: 'The Balkan Dwarf',
    title: 'KeKec Hodler Airdrop',
    pointsTitle: [
      'KeKec Hodler Base Aether:',
      'KeKec Hodler Booster Aether:',
      'KeKec Hodler Referral Aether:',
      'KeKec Hodler Total Aether:',
    ],
    values: [null, null, null, null],
  },
  {
    name: 'LooksRare Token',
    title: 'LooksRare Hodler Airdrop',
    pointsTitle: [
      'LooksRare Hodler Base Aether:',
      'LooksRare Hodler Booster Aether:',
      'LooksRare Hodler Referral Aether:',
      'LooksRare Hodler Total Aether:',
    ],
    values: [null, null, null, null],
  },
  {
    name: 'X2Y2Token',
    title: 'X2Y2 Hodler Airdrop',
    pointsTitle: [
      'X2Y2 Hodler Base Aether:',
      'X2Y2 Hodler Booster Aether:',
      'X2Y2 Hodler Referral Aether:',
      'X2Y2 Hodler Total Aether:',
    ],
    values: [null, null, null, null],
  },
  {
    name: 'Ethereum Name Service',
    title: 'Ens Hodler Airdrop',
    pointsTitle: [
      'Ens Hodler Base Aether:',
      'Ens Hodler Booster Aether:',
      'Ens Hodler Referral Aether:',
      'Ens Hodler Total Aether:',
    ],
    values: [null, null, null, null],
  },
  {
    name: 'Gnosis',
    title: 'Gnosis Hodler Airdrop',
    pointsTitle: [
      'Gnosis Hodler Base Aether:',
      'Gnosis Hodler Booster Aether:',
      'Gnosis Hodler Referral Aether:',
      'Gnosis Hodler Total Aether:',
    ],
    values: [null, null, null, null],
  },
  {
    name: 'Pooh',
    title: 'Pooh Hodler Airdrop',
    pointsTitle: [
      'Pooh Hodler Base Aether:',
      'Pooh Hodler Booster Aether:',
      'Pooh Hodler Referral Aether:',
      'Pooh Hodler Total Aether:',
    ],
    values: [null, null, null, null],
  },
  {
    name: 'MongCoin',
    title: 'MongCoin Hodler Airdrop',
    pointsTitle: [
      'MongCoin Hodler Base Aether:',
      'MongCoin Hodler Booster Aether:',
      'MongCoin Hodler Referral Aether:',
      'MongCoin Hodler Total Aether:',
    ],
    values: [null, null, null, null],
  },
  {
    name: 'Maker',
    title: 'Maker Hodler Airdrop',
    pointsTitle: [
      'Maker Hodler Base Aether:',
      'Maker Hodler Booster Aether:',
      'Maker Hodler Referral Aether:',
      'Maker Hodler Total Aether:',
    ],
    values: [null, null, null, null],
  },
  {
    name: 'Graph Token',
    title: 'The Graph Hodler Airdrop',
    pointsTitle: [
      'The Graph Hodler Base Aether:',
      'The Graph Hodler Booster Aether:',
      'The Graph Hodler Referral Aether:',
      'The Graph Hodler Total Aether:',
    ],
    values: [null, null, null, null],
  },
  {
    name: 'SAND',
    title: 'Sandbox Hodler Airdrop',
    pointsTitle: [
      'Sandbox Hodler Base Aether:',
      'Sandbox Hodler Booster Aether:',
      'Sandbox Hodler Referral Aether:',
      'Sandbox Hodler Total Aether:',
    ],
    values: [null, null, null, null],
  },
  {
    name: 'SushiToken',
    title: 'Sushi Hodler Airdrop',
    pointsTitle: [
      'Sushi Hodler Base Aether:',
      'Sushi Hodler Booster Aether:',
      'Sushi Hodler Referral Aether:',
      'Sushi Hodler Total Aether:',
    ],
    values: [null, null, null, null],
  },
];
export const NFT_VOLUME = [
  {
    name: 'opensea',
    title: 'OpenSea Volume Airdrop',
    pointsTitle: [
      'OpenSea Volume Base Diamonds:',
      'OpenSea Volume Booster Diamonds:',
      'OpenSea Volume Referral Diamonds:',
      'OpenSea Volume Total Diamonds:',
    ],
    values: [null, null, null, null],
  },
  {
    name: 'blur',
    title: 'Blur Volume Airdrop',
    pointsTitle: [
      'Blur Volume Base Diamonds:',
      'Blur Volume Booster Diamonds:',
      'Blur Volume Referral Diamonds:',
      'Blur Volume Total Diamonds:',
    ],
    values: [null, null, null, null],
  },
];
export const LEADERBOARD_RANKING_REWARDS = [
  {
    rank: '1st Place',
    reward: '100K',
    number: '100,000',
  },
  {
    rank: '2nd Place',
    reward: '70K',
    number: '70,000',
  },
  {
    rank: '3rd Place',
    reward: '60K',
    number: '60,000',
  },
  {
    rank: '4th Place',
    reward: '50K',
    number: '50,000',
  },
  {
    rank: '5th Place',
    reward: '40K',
    number: '40,000',
  },
  {
    rank: '6th Place',
    reward: '30K',
    number: '30,000',
  },
  {
    rank: '7th Place',
    reward: '25K',
    number: '25,000',
  },
  {
    rank: '8th Place',
    reward: '20K',
    number: '20,000',
  },
  {
    rank: '9th Place',
    reward: '15K',
    number: '15,000',
  },
  {
    rank: '10th Place',
    reward: '10K',
    number: '10,000',
  },
];
export const END_SEASON_POINTS = [
  {
    name: 'X',
    title: 'X Airdrop',
    pointsTitle: [
      'X Airdrop Base Points:',
      'X Airdrop Booster Points:',
      'X Airdrop Referral Points:',
      'X Airdrop Total Points:',
      'GMI Earned:',
    ],
    values: [null, null, null, null],
  },
  {
    name: 'Liquidity',
    title: 'Liquidity Airdrop',
    pointsTitle: [
      'Liquidity Airdrop Base Points:',
      'Liquidity Airdrop Booster Points:',
      'Liquidity Airdrop Referral Points:',
      'Liquidity Airdrop Total Points:',
      'GMI Earned:',
    ],
    values: [null, null, null, null],
  },
  {
    name: 'Trader',
    title: 'Trader Airdrop',
    pointsTitle: [
      'Trader Airdrop Base Points:',
      'Trader Airdrop Booster Points:',
      'Trader Airdrop Referral Points:',
      'Trader Airdrop Total Points:',
      'GMI Earned:',
    ],
    values: [null, null, null, null],
  },
  {
    name: 'Creator',
    title: 'Creator Airdrop',
    pointsTitle: [
      'Creator Airdrop Base Points:',
      'Creator Airdrop Booster Points:',
      'Creator Airdrop Referral Points:',
      'Creator Airdrop Total Points:',
      'GMI Earned:',
    ],
    values: [null, null, null, null],
  },
  {
    name: 'Token Hodler',
    title: 'Token Hodler Airdrop',
    pointsTitle: [
      'Token Hodler Base Points:',
      'Token Hodler Booster Points:',
      'Token Hodler Referral Points:',
      'Token Hodler Total Points:',
      'GMI Earned:',
    ],
    values: [null, null, null, null],
  },
  {
    name: 'NFT Hodler',
    title: 'NFT Hodler Airdrop',
    pointsTitle: [
      'NFT Hodler Base Points:',
      'NFT Hodler Booster Points:',
      'NFT Hodler Referral Points:',
      'NFT Hodler Total Points:',
      'GMI Earned:',
    ],
    values: [null, null, null, null],
  },
  {
    name: 'NFT Volume',
    title: 'NFT Volume Airdrop',
    pointsTitle: [
      'NFT Volume Base Points:',
      'NFT Volume Booster Points:',
      'NFT Volume Referral Points:',
      'NFT Volume Total Points:',
      'GMI Earned:',
    ],
    values: [null, null, null, null],
  },
  {
    name: 'Zealy',
    title: 'Zealy Airdrop',
    pointsTitle: [
      'Zealy Airdrop Base Points:',
      'Zealy Airdrop Booster Points:',
      'Zealy Airdrop Referral Points:',
      'Zealy Airdrop Total Points:',
      'GMI Earned:',
    ],
    values: [null, null, null, null],
  },
];

export const MAXIMUM_BOOSTER_PERCENTAGE = 370;

// Other
export const DAY_IN_MILLISECONDS = 24 * 60 * 60 * 1000;

export const CHART_TIME_FRAMES = {
  ALL: () => new Date(0),
  '1Y': () => {
    const today = new Date();
    const oneYearAgo = new Date(today);
    oneYearAgo.setFullYear(today.getFullYear() - 1);
    return oneYearAgo;
  },
  '3M': () => {
    const today = new Date();
    const threeMonthsAgo = new Date(today);
    threeMonthsAgo.setMonth(today.getMonth() - 3);
    return threeMonthsAgo;
  },
  '1M': () => {
    const today = new Date();
    const monthAgo = new Date(today);
    monthAgo.setMonth(today.getMonth() - 1);
    return monthAgo;
  },
};

export const CHART_DEFAULT_TIME_FRAME = 'ALL';
export const AIRDROP_STARTING_POINT_FOR_CHART = new Date('2024-06-01');
