import { createContext, useContext, useEffect, useState } from 'react';
import { useCookies } from 'react-cookie';
import { useAccount, useConnect } from 'wagmi';
import { signMessage } from '@wagmi/core';

import { SIGNATURE_EXPIRY_TIME, AUTH_MESSAGE_TO_SIGN } from 'constants/index';
import { config } from 'constants/walletConfig';
import userService from 'services/user';
import useNotification from 'hooks/useNotification';

const WalletContext = createContext();
export const useWallet = () => useContext(WalletContext);

/********************  Context Provider ********************/
export const WalletProvider = ({ children }) => {
  const { address, status } = useAccount();
  const { connectors } = useConnect();
  const showNotification = useNotification();

  const [wallet, setWallet] = useState(null);
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [twitterUser, setTwitterUser] = useState(null);
  const [walletModalOpen, setWalletModalOpen] = useState(false);
  const [cookies, setCookie] = useCookies(['user']);

  const metamask = connectors?.find((connector) => connector.name === 'MetaMask');

  useEffect(() => {
    const token = localStorage.getItem('token');
    const tokenExpiry = parseInt(localStorage.getItem('token_expiry'), 10) || 0;
    const tokenWallet = localStorage.getItem('token_wallet');
    const now = Date.now();
    const isTokenExpired = tokenExpiry < now;

    const authenticateWallet = async () => {
      try {
        const walletAddress = address.toLowerCase();
        const nonceData = await userService.getNonce(walletAddress);
        if (nonceData) {
          const message = AUTH_MESSAGE_TO_SIGN(walletAddress, nonceData.data.nonce);
          const sign = await signMessage(config, {
            account: walletAddress,
            message,
          });

          const authResponseData = await userService.authenticate(
            walletAddress,
            sign,
            localStorage.getItem('linked_main_wallet'),
          );
          if (authResponseData) {
            localStorage.setItem('token', authResponseData.data.auth.token);
            localStorage.setItem('token_wallet', walletAddress);
            localStorage.setItem('token_expiry', Date.now() + SIGNATURE_EXPIRY_TIME);
            localStorage.setItem('linked_wallets', JSON.stringify(authResponseData.data.linked));
            localStorage.setItem('linked_main_wallet', authResponseData.data.mainwallet);
            authResponseData.data.twitterUser &&
              localStorage.setItem('twitter', authResponseData.data.twitterUser);
            setAuthenticatedState();
            showNotification({
              type: 'success',
              message: 'Wallet connected successfully',
            });
          }
        }
      } catch (e) {
        setIsAuthenticated(false);
        showNotification({
          type: 'error',
          message: 'Wallet connection failed, please try again',
        });
        localStorage.removeItem('token');
        localStorage.removeItem('token_wallet');
        localStorage.removeItem('token_expiry');
        localStorage.removeItem('twitter');
      }
    };

    const setAuthenticatedState = () => {
      setWallet(localStorage.getItem('token_wallet'));
      localStorage.getItem('twitter') && setTwitterUser(localStorage.getItem('twitter'));
      setIsAuthenticated(true);
    };

    if (address && !cookies.address) {
      setCookie('address', address.toLowerCase(), { path: '/' });
    }

    setIsAuthenticated(false);
    if (status === 'disconnected') {
      if (!token || isTokenExpired) {
        return;
      }

      setAuthenticatedState();
      return;
    }

    if (status === 'connected') {
      if (!token || isTokenExpired || tokenWallet !== address.toLowerCase()) {
        authenticateWallet();
        return;
      }

      setAuthenticatedState();
    }
  }, [status, address]);

  const connectWallet = async () => {
    try {
      if (metamask) {
        await metamask.connect();
      } else {
        setWalletModalOpen(true);
      }
    } catch (error) {
      showNotification({
        type: 'error',
        message: 'Please try again',
      });
      console.error(error);
    }
  };

  const closeModal = () => setWalletModalOpen(false);

  return (
    <WalletContext.Provider
      value={{
        isAuthenticated,
        wallet,
        twitterUser,
        walletModalOpen,
        connectWallet,
        closeModal,
      }}
    >
      {children}
    </WalletContext.Provider>
  );
};
