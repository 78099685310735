import { UserApi } from 'clientSDK';

const userApi = new UserApi();

export default {
  getNonce: async (address) => await userApi.getNonce(address),

  authenticate: async (address, signature, mainWallet) =>
    await userApi.authenticate({ address, signature, mainWallet }),

  getAirdropData: async () => await userApi.getAirdropData(),

  logout: async () => await userApi.logout(),

  checkZealyMembership: async (zealyId) => await userApi.checkZealyMembership({ zealyId }),

  getHodlerBalance: async () => await userApi.getHodlerBalance(),

  getNftVolume: async () => await userApi.getNftVolume(),

  checkEarlyDegen: async () => await userApi.checkEarlyDegen(),

  claimPowerTweet: async (number) => await userApi.claimPowerTweet(number),

  checkClaimReferralProgram: async () => await userApi.checkClaimReferralProgram(),

  getUserRewards: async (season) => await userApi.getUserRewards({ season }),

  getTotalRewards: async () => await userApi.getTotalRewards(),

  claimDailyCheck: async () => await userApi.claimDailyCheck(),
};
